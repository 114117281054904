import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const AppBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 890px;
  margin: 0 auto;
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: ${grayScale.white};
  @media (min-width: 992px)
  {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 54px;
    padding-bottom: 54px;
  }
`
export const BenefitItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
  @media (min-width: 992px)
  {
    margin-bottom: initial;
  }
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: ${primaryColors.baby_blue};
  border-radius: 16px;
  margin-bottom: 12px;
  @media (min-width: 992px)
  {
    width: 87px;
    height: 87px;
    margin-bottom: 20px;
  }
`
export const BenefitDescription = styled.span`
  ${textStyles.landing_400_14px}
  text-align: center;
  width: 100%;
  max-width: 260px;
  @media (min-width: 992px)
  {
    width: 100%;
    max-width: 235px;
    ${textStyles.landing_400_16px}
  }
`