import CheckCircleIcon from 'assets/icons/CheckCircleIcon';
import React from 'react';
import { SecureAgainstFraudContainer, Title, Subtitle, Row, Column, SecureItem, SecureText, SecureImage } from './styles';
import AntifraudeImage from 'assets/images/landing/hero-antifraude.png'
import { primaryColors } from 'theme/styleguide';
import useWindowWidth from 'utils/useWindowWidth';

export default function SecureAgainstFraud() {
  const windowWidth = useWindowWidth()
  const securityTasks = [
    "Análise antifraude integrada e automática em todos os pagamentos",
    "Investigação detalhada para garantir maior taxa de aprovação em pagamentos legítimos",
    "Cobertura integral das vendas em caso de fraude e chargeback indevido",
  ]
  return <>
    <SecureAgainstFraudContainer>
      <Title>
        Proteção contra fraudes
      </Title>
      <Subtitle>
        Proteja sua empresa contra fraudes e chargeback
      </Subtitle>
      <Row>
        <SecureImage
          src={AntifraudeImage}
          alt="Segurança contra fraudes"
        />
        <Column>
          {
            securityTasks.map((e, i) => {
              return (
                <SecureItem key={i}>
                  <CheckCircleIcon
                    color={primaryColors.green_5}
                    size={windowWidth >= 992 ? 2.08 : 1.25}
                  />
                  <SecureText>
                    {e}
                  </SecureText>
                </SecureItem>
              )
            })
          }
        </Column>
      </Row>
    </SecureAgainstFraudContainer>
  </>;
}
