import React from 'react';
import { Field } from 'formik';
import { Collapse, MenuItem } from '@material-ui/core';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { EnterpriseFieldsContainer } from './styles';
import { formatIdentifier } from 'utils/formatIdentifier';
import ConstitutionPicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/ConstitutionPicker';
import MainActivityAutoComplete from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/MainActivityAutoComplete';
import { StyledTextField, StyledMoneyTextField, FieldSectionTitle, StyledAlert } from 'components/Dashboard/AccountDataMenu/styles';
import ReportProblem from 'assets/icons/ReportProblem';
import { primaryColors } from 'theme/styleguide';
import TelephoneContactFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/TelephoneContactFields';

export default function EnterpriseFields({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  legalNatureList = [],
  constitutionList = [],
  hasDigitalAccount = false,
}) {
  const handleMonthlyRevenue = (_e, value) => {
    setFieldValue("monthlyRevenue", value)
  }
  return <>
    <EnterpriseFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Field
              as={StyledTextField}
              name="socialName"
              label="Razão social"
              size="small"
              error={touched.socialName && !!errors.socialName}
              helperText={touched.socialName && errors.socialName}
              disabled={disabledForm}
            />
            <Field
              as={StyledTextField}
              name="CNPJ"
              label="CNPJ"
              size="small"
              error={touched.CNPJ && !!errors.CNPJ}
              helperText={touched.CNPJ && errors.CNPJ}
              inputProps={{ maxLength: 18 }}
              onChange={(e) => {
                setFieldValue("CNPJ", formatIdentifier(e.target.value))
              }}
              disabled
            />
            <Field
              as={StyledTextField}
              name="fantasyName"
              label="Nome fantasia"
              size="small"
              error={touched.fantasyName && !!errors.fantasyName}
              helperText={touched.fantasyName && errors.fantasyName}
              disabled={disabledForm}
            />
            {
              hasDigitalAccount &&
              <>
                <Field
                  as={StyledTextField}
                  name="legalNature"
                  label="Natureza jurídica"
                  size="small"
                  select
                  error={touched.legalNature && !!errors.legalNature}
                  helperText={touched.legalNature && errors.legalNature}
                  disabled={disabledForm}
                >
                  {
                    legalNatureList.map((e, i) => (
                      <MenuItem
                        value={e.id}
                        key={i}
                      >
                        {e.name}
                      </MenuItem>
                    ))
                  }
                </Field>
                <Field
                  as={ConstitutionPicker}
                  name="constitutionForm"
                  label="Forma de constituição"
                  size="small"
                  options={constitutionList}
                  error={touched.constitutionForm && !!errors.constitutionForm}
                  helperText={
                    (touched.constitutionForm && errors.constitutionForm) ||
                    "É a caracterização da empresa, que pode ser constituída de uma ou mais pessoas empresárias, sócias ou acionistas."
                  }
                  disabled={disabledForm}
                />
                <Field
                  as={DatePicker}
                  name="formationDate"
                  label="Data de abertura da empresa"
                  size="small"
                  setFieldValue={setFieldValue}
                  error={touched.formationDate && !!errors.formationDate}
                  helperText={touched.formationDate && errors.formationDate}
                  disabled={disabledForm}
                  $fullWidth={false}
                />
                <Field
                  as={MainActivityAutoComplete}
                  name="mainActivity"
                  label="Atividade pricipal"
                  setFieldValue={setFieldValue}
                  values={values}
                  error={touched.mainActivity && !!errors.mainActivity}
                  helperText={
                    (touched.mainActivity && errors.mainActivity) ||
                    "A atividade principal é a que gera maior receita para o estabelecimento e corresponde a atividade descrita no código CNAE."
                  }
                  disabled={disabledForm}
                  fullWidth
                />
                <Field
                  as={StyledMoneyTextField}
                  name="monthlyRevenue"
                  label="Faturamento mensal"
                  size="small"
                  onChange={handleMonthlyRevenue}
                  error={touched.monthlyRevenue && !!errors.monthlyRevenue}
                  helperText={
                    (touched.monthlyRevenue && errors.monthlyRevenue) ||
                    `Faturamento é o total em R$ das vendas de uma empresa. Neste caso, você deve informar o valor médio total das suas vendas no período de um mês.\n\nEx: uma pessoa que vende bolos, vendeu 20 bolos em um mês no valor de R$ 50,00 cada. Então seu faturamento mensal foi de R$ 1.000,00.\n `
                  }
                  disabled={disabledForm}
                />
              </>
            }
            <FieldSectionTitle>
              Contato
            </FieldSectionTitle>
            <TelephoneContactFields
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabledForm={disabledForm}
              hideContainerPadding
            />
            <FieldSectionTitle>
              Endereço da empresa
            </FieldSectionTitle>
            <AddressFields
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabledForm={disabledForm}
            />
            <Collapse
              in={!disabledForm}
            >
              <StyledAlert
                severity="error"
                icon={<ReportProblem
                  color={primaryColors.red_balance}
                />}
              >
                <b>
                  {`Atenção! Seus recebimentos podem ficar temporariamente bloqueados se a razão social ou endereço da empresa forem editados.\n\n`}
                </b>
                {`Isso ocorre devido a uma rápida validação automática que fazemos dos novos dados informados como medida de segurança para garantir a autenticidade da razão social e endereço. Em alguns casos, poderá ser solicitado que você envie documentos para comprovação.\n\nApós os novos dados serem validados, os recebimentos serão desbloqueados e a conta poderá continuar operando normalmente na Vindi.`}
              </StyledAlert>
            </Collapse>
          </>
      }
    </EnterpriseFieldsContainer>
  </>;
}