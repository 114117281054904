import ShoppingCartIcon from 'assets/icons/ShoppingCartIcon';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { fetchSales } from 'services/GetSalesService/GET/SalesService';
import { MoneySubtitle, MoneyText, RoundedButton } from 'components/Dashboard/DashboardOverview/styles';
import HorizontalScrollable from 'components/Dashboard/HorizontalScrollable';
import { Column, Row, TotalOfSellingContainer, SpinnerContainer } from './styles';
import { CircularProgress } from '@material-ui/core';
import { formatToBRL } from 'utils/formatToBRL';
import { useRef } from 'react';
const filterButtonsList = [
  {
    title: "Hoje",
    value: 1,
  },
  {
    title: "Últimos 7 dias",
    value: 7
  },
  {
    title: "Últimos 30 dias",
    value: 30
  },
]

export default function TotalOfSelling() {
  const mounted = useRef(false);
  const [totalMoney, setTotalMoney] = useState("0,00")
  const [selectedFilter, setSelectedFilter] = useState(1)
  const [loading, setLoading] = useState(true)
  const handleFilter = (value) => {
    setSelectedFilter(value)
  }

  const getTotalOfSellingData = useCallback(async () => {
    setLoading(true)
    const day = 86400000
    const end = Math.round(Date.now() / 1000)
    const start = Math.round(((Date.now()) - (selectedFilter * day)) / 1000)
    const response = await fetchSales(start, end)
    if (mounted.current) {
      if (response?.data?.options?.sum_price_seller)
        setTotalMoney(formatToBRL(response?.data?.options?.sum_price_seller, true))
      else {
        setTotalMoney("0,00")
      }
      setLoading(false)
    }
  }, [selectedFilter])

  useEffect(() => {
    getTotalOfSellingData()
  }, [getTotalOfSellingData, selectedFilter])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])
  return <>
    <TotalOfSellingContainer>
      <HorizontalScrollable>
        {
          [...filterButtonsList].reverse().map((e, i) => {
            return (
              <RoundedButton
                onClick={() => { handleFilter(e.value) }}
                $selected={e.value === selectedFilter}
                key={i}
              >
                {e.title}
              </RoundedButton>
            )
          })
        }
      </HorizontalScrollable>
      <Row>
        <ShoppingCartIcon />
        <Column>
          {loading ?
            <SpinnerContainer>
              <CircularProgress size="25px" />
            </SpinnerContainer>
            : <MoneyText>
              R$ {totalMoney}
            </MoneyText>}
          <MoneySubtitle>
            Total vendido
          </MoneySubtitle>
        </Column>
      </Row>
    </TotalOfSellingContainer>
  </>;
}
