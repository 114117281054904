import styled from "styled-components";
import { primaryColors, textStyles } from "theme/styleguide";

export const SingInLessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 335px;
  text-align: center;
  padding: 28px 24px;
  background-color: ${primaryColors.blue_X};
  img{
    max-width: 100%;
  }
  @media (min-width: 992px)
  {
    padding: 28px;
  }
`
export const Title = styled.span`
  ${textStyles.landing_700_18px}
  margin-top: 30px;
  margin-bottom: 16px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_700_24px}
  }
`
export const Body = styled.span`
  width: 100%;
  max-width: 900px;
  ${textStyles.landing_600_14px}
  font-weight: 500;
  white-space: pre-wrap;
  @media (min-width: 992px)
  {
    ${textStyles.landing_600_16px}
  }
`