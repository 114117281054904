import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetAccountPendencies = async ({
  showAllFields = false,
}) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/account/pendencies", {
    headers: {
      code
    }
  })
    .then(({ data }) => {
      if (showAllFields) {
        return {
          ...data[0],
          formation_date: true,
          politically_exposed: true,
          mother_name: true,
          company_beneficiary: true,
          main_activity_id: true,
          constitution_form_id: true,
          monthly_revenue: true,
          legal_nature_id: true,
          company_size_id: true,
          tax_regime_id: true,
          partner_pendency: true,
          phone: true,
          address: true,
          birth_date: true,
          bank_account_dashboard: true, // Atributo auxiliar para mostrar os campos de dados bancários na Dashboard, uma vez que os atributos bank_account e bank_cip não podem ser sobrescritos pois são gatilhos em alguns componentes
        }
      } else {
        if (data.length === 0) {
          console.error('Pendencies not found')
          return {
            formation_date: false,
            politically_exposed: false,
            mother_name: false,
            company_beneficiary: false,
            main_activity_id: false,
            constitution_form_id: false,
            monthly_revenue: false,
            legal_nature_id: false,
            company_size_id: false,
            tax_regime_id: false,
            partner_pendency: false,
            phone: false,
            address: false,
            birth_date: false,
            bank_account: false,
            bank_cip: false,
          }
        } else {
          return data[0]
        }
      }
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}