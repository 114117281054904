import WatchLaterIcon from 'assets/icons/WatchLaterIcon';
import React, { useEffect, useState } from 'react';
import { fetchGetStatements } from 'services/Statements/GET/GetStatementsService';
import CardListViewer from 'components/Dashboard/CardListViewer';
import { CardHeaderRow, CardTitle, SmallInstruction, StyledTextButton } from 'components/Dashboard/DashboardOverview/styles';
import TableViewer from 'components/Dashboard/TableViewer';
import { LastTransactionsContainer, EmptyStateImage, SpinnerContainer } from './styles';
import { CircularProgress } from '@material-ui/core';
import EmptyTransaction from 'assets/images/emptyTransaction.png'
import { useRef } from 'react';
import { useCallback } from 'react';
import ExternalService from 'utils/ExternalService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function LastTransactions() {
  const mounted = useRef(false);
  const [transactions, setTransacations] = useState([])
  const [isLoading, setLoading] = useState(true)

  const getTransactions = useCallback(async () => {
    const { data } = await fetchGetStatements()
    if (mounted.current) {
      setTransacations(data?.splice(0, 10) || [])
      setLoading(false)
    }
  }, [])

  const handleOpenPortal = () => {
    GAEventTracker({
      category: "Página Visão Geral",
      action: "Ver todas de ultimas transações",
      label: `Clicar no botão "ver todas" da sessão últimas transações`
    })
    window.open(`${ExternalService.buildExternalUrl('intermediador')}/`, "_blank")
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    getTransactions()
  }, [getTransactions])

  return <>
    <LastTransactionsContainer>
      <CardHeaderRow>
        <WatchLaterIcon />
        <CardTitle>
          Últimas transações
        </CardTitle>
      </CardHeaderRow>
      {
        isLoading ?
          <SpinnerContainer>
            <CircularProgress size="25px" />
          </SpinnerContainer>
          :
          <>
            <TableViewer
              tableBodyData={transactions}
              emptyData={{
                img: <EmptyStateImage src={EmptyTransaction} />,
                title: 'Você ainda não possui transações',
                subTitle: 'Por enquanto, nenhuma transação foi registrada\n para exibirmos aqui.'
              }}
            />
            <CardListViewer
              cardData={transactions}
            />
            {
              transactions.length > 0 &&
              <>
                <StyledTextButton
                  variant="outlined"
                  onClick={handleOpenPortal}
                >
                  Ver todas
                </StyledTextButton>
                <SmallInstruction>
                  Ao clicar em “Ver todas” você será direcionado para o portal Vindi
                </SmallInstruction>
              </>
            }
          </>
      }
    </LastTransactionsContainer>
  </>;
}
