import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const SMSPhoneVerificationFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ $isOnboarding }) => $isOnboarding ? "72px" : "162px"};
  @media (min-width: 768px){
    margin-bottom: 192px;
  }
`
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self:center;
  margin-bottom: 28px;
  @media (min-width: 768px){
    align-self:flex-start;
    margin-bottom: 24px;
  }
`
export const CodeInput = styled(TextField)`
  width: 38px;
  margin-right: 10px;
`
export const CodeInstruction = styled.span`
  ${textStyles.tray_text_12px}
  text-align: center;
  @media (min-width: 768px){
    text-align: start;
    ${textStyles.tray_text_14px}
  }
`
export const InstructionLabel = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 28px;
`
export const Image = styled.img`
  display:none;
  @media (min-width: 768px){
    display: block;
    margin: auto;
    margin-top: -50px;
  }

`
export const StyledButton = styled(Button)`
  min-width: 96px !important;
  width: 96px !important;
  margin-top: 28px;
  align-self:center;
  @media (min-width: 768px){
    align-self:flex-start;
  }
`