import React from 'react';
import { Form, Formik } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import LoadableButton from 'components/shared/LoadableButton';
import { StyledButtonsContainer } from 'components/Dashboard/AccountDataMenu/styles';
import { Button, Collapse } from '@material-ui/core';
import { BankAccountFormContainer } from './styles';
import BankAccountFields from 'fields/Dashboard/MyAccountTab/AccountDataMenu/shared/BankAccountFields/BankAccountFields';
import useBankAccountDataSetup from 'setup/Dashboard/MyAccountTab/AccountDataMenu/shared/useBankAccountDataSetup';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function BankAccountForm() {
  const { isAccountDataMenuFormsDisabled, toggleAccountDataMenuFormsDisabled } = useDashboardMyAccount()
  const { initialValues, validationSchema, accountTypesList, bankOptionsList, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen } = useBankAccountDataSetup({
    afterSubmit: () => {
      toggleAccountDataMenuFormsDisabled()
    },
    reloadAfterSubmit: true,
  })
  const handleCancel = (formikProps) => {
    formikProps.resetForm()
    toggleAccountDataMenuFormsDisabled()
  }
  return <>
    <BankAccountFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <BankAccountFields
                {...formikProps}
                isLoading={isLoading}
                accountTypesList={accountTypesList}
                bankOptionsList={bankOptionsList}
                disabledForm={isAccountDataMenuFormsDisabled}
              />
              <Collapse
                in={!isAccountDataMenuFormsDisabled}
              >
                <StyledButtonsContainer>
                  <LoadableButton
                    type="submit"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                    isLoading={formikProps.isSubmitting}
                  >
                    Salvar
                  </LoadableButton>
                  <Button
                    variant="outlined"
                    onClick={() => { handleCancel(formikProps) }}
                    disabled={formikProps.isSubmitting || isLoading}
                  >
                    Cancelar
                  </Button>
                </StyledButtonsContainer>
              </Collapse>
            </Form>
          )
        }
      </Formik>
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Salvo"
      />
    </BankAccountFormContainer>
  </>;
}