import React from 'react';
import FormButtons from 'components/SettingsMenu/FormButtons';
import { BillingEmailsMenuItemContainer } from './styles';
import { useDashboard } from 'context/DashboardContext';
import { Form, Formik } from 'formik';
import BillingEmailForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/BillingEmailForm';
import useBillingEmailSetup from 'components/FormsSetup/MainSettings/useBillingEmailsSetup';

export default function BillingEmailsMenuItem() {
  const { disabledEdition } = useDashboard()
  const { initialValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen } = useBillingEmailSetup({})

  return <>
    <BillingEmailsMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <BillingEmailForm
                {...formikProps}
                isLoading={isLoading}
                disabled={disabledEdition}
              />
              <FormButtons
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                {...formikProps}
              />
            </Form>
          )
        }
      </Formik>
    </BillingEmailsMenuItemContainer>
  </>;
}
