import React from 'react';
import { Form, Formik } from 'formik';
import { EnterpriseProofingUploadMenuItemContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { StyledLoadableButton } from 'components/Dashboard/DashboardAccountVerification/styles';
import useEnterpriseProofingUploadSetup from 'components/FormsSetup/AccountVerification/useEnterpriseProofingUploadSetup';
import EnterpriseProofingUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/EnterpriseProofingUploadForm';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function EnterpriseProofingUploadMenuItem() {
  const { goBackToAccountVerificationMenu } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, enterpriseData, documentRequired, documentStatus } = useEnterpriseProofingUploadSetup({
    afterSubmit: goBackToAccountVerificationMenu
  })
  return <>
    <EnterpriseProofingUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EnterpriseProofingUploadForm
                isLoading={isLoading}
                enterpriseData={enterpriseData}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                sendButtonText={"Continuar"}
                {...formikProps}
              />
              <ButtonsContainer>
                <StyledLoadableButton
                  type="submit"
                  disabled={
                    (documentRequired && !formikProps.isValid) ||
                    (isLoading || formikProps.isSubmitting)
                  }
                  isLoading={formikProps.isSubmitting}
                >
                  {
                    ["approved", "pending"].includes(documentStatus?.status?.tag) ?
                      "Continuar" :
                      "Enviar"
                  }
                </StyledLoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </EnterpriseProofingUploadMenuItemContainer>
  </>;
}
