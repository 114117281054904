import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { fetchUploadCompany } from 'services/Document/POST/UploadCompanyService';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { GAEventTracker } from 'utils/GAEventTracker';

const fetchGetAa = async () => ({ letterOfAttorney: "" })

export default function useAttorneyPowerUploadSetup({
  afterSubmit = () => { }
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [documentRequired] = useState(false)
  const [documentStatus] = useState()
  const [initialValues, setInitialValues] = useState({
    letterOfAttorney: ""
  })
  const validationSchema = object({
    letterOfAttorney: string()
      .required(),
  })

  const fetchAccountData = useCallback(async () => {
    const { letterOfAttorney } = await fetchGetAa()
    if (mounted.current) {
      setInitialValues({
        letterOfAttorney
      })
      setLoading(false)
    }
  }, [])

  const handleSubmit = async ({
    letterOfAttorney
  }) => {
    if (letterOfAttorney) {
      const fileLetterOfAttorney = await parseDataURLtoFile(letterOfAttorney, "letterOfAttorney")
      await fetchUploadCompany({
        file: fileLetterOfAttorney,
      })
        .then((res) => {
          if (res?.status === 200) {
            GAEventTracker({
              category: "Onboarding",
              action: "Anexar procuração",
              label: "Anexar procuração da empresa e salvar"
            })
            afterSubmit()
          }
        })
    } else {
      afterSubmit()
    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    documentRequired,
    documentStatus,
  })
}