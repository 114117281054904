import DescriptionIcon from "assets/icons/DescriptionIcon";
import PeopleAltIcon from "assets/icons/PeopleAltIcon";
import PersonIcon from "assets/icons/PersonIcon";
import PhotoCameraIcon from "assets/icons/PhotoCameraIcon";
import PlaceIcon from "assets/icons/PlaceIcon";
import SavingsIcon from "assets/icons/SavingsIcon";
import AddressProofingUploadMenuItem from "components/AccountVerificationMenu/AddressProofingUploadMenuItem";
import BankAccountProofingUploadMenuItem from "components/AccountVerificationMenu/BankAccountProofingUploadMenuItem";
import EnterpriseProofingUploadMenuItem from "components/AccountVerificationMenu/EnterpriseProofingUploadMenuItem";
import PartnersDocumentsUploadMenuItem from "components/AccountVerificationMenu/PartnersDocumentsUploadMenuItem";
import UserFacePictureUploadMenuItem from "components/AccountVerificationMenu/UserFacePictureUploadMenuItem";
import UserIdUploadMenuItem from "components/AccountVerificationMenu/UserIdUploadMenuItem";
import { primaryColors } from "theme/styleguide";
import { createId } from "utils/createId";

export const accountVerificationDictionary = {
  titularId: {
    title: "Foto do RG ou CNH",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <PersonIcon
      size={.8}
      color={primaryColors.blue_3}
      opacity={1}
    />,
    buttonId: createId("envio_docs", "enviar_foto_RG_titular"),
    component: <UserIdUploadMenuItem />,
  },
  titularSelfie: {
    title: "Foto do rosto da pessoa titular da conta - selfie",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <PhotoCameraIcon />,
    buttonId: createId("envio_docs", "enviar_selfie_titular"),
    component: <UserFacePictureUploadMenuItem />,
  },
  addressProofing: {
    title: "Comprovante de endereço da empresa ou do(a) titular (contas PF)",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <PlaceIcon
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_comprovante_endereco"),
    component: <AddressProofingUploadMenuItem />,
  },
  businessProofing: {
    title: "Documento de constituição da empresa",
    description: "Envie um documento que comprove a existência da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <DescriptionIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_doc_constituicao_titular"),
    component: <EnterpriseProofingUploadMenuItem />,
  },
  // partnersId: {
  //   personalPartnerTitle: "Foto do RG ou CNH",
  //   businessPartnerTitle: "Documento de constituição da empresa",
  //   personaldescription: "Envie uma foto do RG ou CNH do(a) sócio(a) da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
  //   businessdescription: "Envie o documento de constituição da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
  //   icon: <PeopleAltIcon
  //     size={.8}
  //     color={primaryColors.blue_3}
  //     opacity={1}
  //   />,
  //   component: <PartnersDocumentsUploadMenuItem />,
  // },
  businessPartnerProofing: {
    title: "Documento de constituição da empresa sócia",
    description: "Envie um documento que comprove a existência da empresa sócia.\nApós clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <DescriptionIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_doc_constituicao_socio"),
    component: <PartnersDocumentsUploadMenuItem />,
  },
  personalPartnerId: {
    title: "Foto do RG ou CNH",
    description: "Envie uma foto do RG ou CNH do(a) sócio(a) da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <PeopleAltIcon
      size={.8}
      color={primaryColors.blue_3}
      opacity={1}
    />,
    buttonId: createId("envio_docs", "enviar_foto_RG_socio"),
    component: <PartnersDocumentsUploadMenuItem />,
  },
  bankProofing: {
    title: "Comprovante de conta bancária",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <SavingsIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_comprovante_conta_bancaria"),
    component: <BankAccountProofingUploadMenuItem />,
  },
}
export const UNUSED_accountVerificationDictionary = {
  userIdUploadForm: {
    title: "Foto RG ou CNH",
    component: <UserIdUploadMenuItem />,
    description: `Para esta verificação, é necessário enviar uma foto aproximada do seu RG ou CNH.\n\nNo caso de Pessoa Jurídica, o documento deverá ser do proprietário ou representante legal da empresa.`,
    alert: `Algumas transações podem ficar indisponíveis enquanto o envio da foto não for realizado e analisado.`,
    rejectedAlert: `A foto enviada foi reprovada. Por favor, faça um novo envio.`,
  },
  bankAccountProofingUploadForm: {
    title: "Comprovante de conta bancária",
    component: <BankAccountProofingUploadMenuItem />,
    description: `Para fazer a verificação da conta bancária, envie um comprovante que contenha os dados da conta cadastrada.\n\nNa próxima tela você poderá conferir a lista dos comprovantes aceitos para envio.`,
    automaticVerificationDescription: `Para fazer a verificação da conta bancária, atualize os dados da conta cadastrada.\n\nNa próxima tela você poderá encontrar o formulário de atualização de conta bancária na seção Conta bancária do formulário Minha conta.`,
    alert: `As transações de saque ficarão indisponíveis enquanto o comprovante não for enviado e analisado.`,
    rejectedAlert: `O comprovante enviado foi reprovado. Por favor, faça um novo envio.`,
  },
  bankAccountRegistrationForm: {
    title: "Registro de dados bancários",
    component: <BankAccountProofingUploadMenuItem />,
    description: `Para fazer a verificação da conta bancária, atualize os dados da conta cadastrada.\n\nNa próxima tela você poderá realizar a atualização de seus dados na aba de Dados bancários, na página Dados da conta.`,
    alert: `As transações de saque ficarão indisponíveis enquanto os dados bancários não forem atualizados, analisados e então aprovados.`,
    rejectedAlert: `Validação de conta bancária por PIX - conta bancária rejeitada.`,
  },
  addressProofingUploadForm: {
    title: "Comprovante de endereço",
    component: <AddressProofingUploadMenuItem />,
    description: `Para fazer a verificação do endereço para contas pessoa física, envie um comprovante cujo titular seja o mesmo da conta Vindi ou familiares próximos (pai, mãe, cônjugue).\n\nPara contas pessoa jurídica, a titularidade deverá ser a mesma do CNPJ cadastrado ou sócios.\n\nNa próxima tela você poderá conferir a lista dos comprovantes aceitos para envio.`,
    alert: `Algumas transações podem ficar indisponíveis enquanto o envio não for realizado e analisado.`,
    rejectedAlert: `O comprovante enviado foi reprovado. Por favor, faça um novo envio.`,
  },
  enterpriseProofingUploadForm: {
    title: "Documento de constituição da empresa",
    component: <EnterpriseProofingUploadMenuItem />,
    description: `Para esta verificação, envie o documento de constituição da empresa.\n\nNa próxima tela você poderá conferir a lista dos documentos aceitos para envio.`,
    alert: `Algumas transações podem ficar indisponíveis enquanto o envio não for realizado e analisado.`,
    rejectedAlert: `O documento enviado foi reprovado. Por favor, faça um novo envio.`,
  },
}