import YapayIcon from 'assets/icons/YapayIcon';
import React, { useEffect, useRef, useState } from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import { StickyHeaderContainer, StyledButton } from './styles';

export default function StickyHeader({
  handleInstallApp,
  CookieRequired = false
}) {
  const windowWidth = useWindowWidth()
  const mounted = useRef(true);
  const [isVisibible, setVisible] = useState(false)
  window.onscroll = () => {
    if (mounted.current) {
      const appBaner = document.getElementById("appBaner")
      const stickyHeaderOffset = windowWidth >= 768 ? 100 : 60
      if (window.pageYOffset > appBaner.offsetHeight - stickyHeaderOffset) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <StickyHeaderContainer
      $isVisibible={isVisibible}
    >
      <YapayIcon
        color="white"
        size={windowWidth >= 768 ? 1.54 : 0.77}
      />
      <StyledButton
        onClick={handleInstallApp}
        disabled={CookieRequired}
      >
        Instalar App
      </StyledButton>
    </StickyHeaderContainer>
  </>;
}
