import React, { useRef } from 'react';
import { CreditCardMenuItemContainer, Instruction, Subtitle } from './styles';
import { Form, Formik } from 'formik';
import CreditCardForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/CreditCardForm';
import FormButtons from 'components/SettingsMenu/FormButtons';
import { useDashboard } from 'context/DashboardContext';
import useCreditCardSetup from 'components/FormsSetup/MainSettings/useCreditCardSetup';
import { StyledAlert } from 'components/MyAccountMenu/AtentionAlertMyAccount/styles';
import ReplyParcelsOptions from 'components/Dashboard/ReplyParcelsOptions';

export default function CreditCardMenuItem() {
  const { disabledEdition } = useDashboard()
  const {
    initialValues,
    validationSchema,
    parcelsOptions,
    taxValue,
    isLoading,
    handleSubmit,
    isSnackbarOpen,
    setSnackbarOpen,
    replySelectedParcels,
    setReplySelectedParcels
  } = useCreditCardSetup({})
  const formikRef = useRef()

  return <>
    <CreditCardMenuItemContainer>
      {
        !isLoading &&
        <>
          <Subtitle>
            Parcelamento de vendas sem juros
          </Subtitle>
          <Instruction>
            Para parcelamentos sem juros, não haverá acréscimo para o comprador. O valor referente aos juros do parcelamento será descontado do vendedor.
          </Instruction>
          <Instruction $smallBorderBottom>
            Defina o parcelamento sem juros para cada bandeira
          </Instruction>
        </>
      }
      {
        !isLoading &&
        <StyledAlert
          severity="info"
        >
          Atenção: as configurações de parcelamento sem juros devem ser iguais as configurações de parcelamento feitas no painel da sua loja.
        </StyledAlert>
      }
      <ReplyParcelsOptions
        parcelsOptions={parcelsOptions}
        formikRef={formikRef}
        replySelectedParcels={replySelectedParcels}
        setReplySelectedParcels={setReplySelectedParcels}

      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={formikRef}

      >
        {
          ({ ...formikProps }) => (
            <Form>
              <CreditCardForm
                {...formikProps}
                parcelsOptions={parcelsOptions}
                isLoading={isLoading}
                taxValue={taxValue}
                disabled={disabledEdition}
                replySelectedParcels={replySelectedParcels}

              />
              <FormButtons
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                onCancel={() => {
                  setReplySelectedParcels(false)
                }}
                {...formikProps}
              />
            </Form>
          )
        }
      </Formik>
    </CreditCardMenuItemContainer>
  </>;
}
