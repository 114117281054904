/* eslint-disable max-len */
import React from 'react';

export default function PagNFinIcon({ size = 1, color = "#236AC8" }) {
  return <>
    <i>
      <svg
        width={50 * size}
        height={42 * size}
        viewBox="0 0 50 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M41.2729 21.2464V5.17577C41.2729 2.76158 39.318 0.795654 36.918 0.795654H4.78359C2.38359 0.795654 0.427856 2.76158 0.427856 5.17577V26.7856C0.427856 29.1998 2.38359 31.1657 4.78359 31.1657H29.6582V31.3595C29.6582 36.9964 34.2172 41.5809 39.8221 41.5809C45.4254 41.5809 49.9844 36.9964 49.9844 31.3603C49.9844 26.221 46.1893 21.9572 41.2729 21.2464ZM4.78359 3.71546H36.918C37.7213 3.71546 38.3688 4.36808 38.3688 5.17577V6.05212H3.33277V5.17577C3.33277 4.36808 3.98114 3.71546 4.78441 3.71546H4.78359ZM3.33195 8.97192H38.3688V11.8925H3.33195V8.97192ZM4.78359 28.2451C3.98032 28.2451 3.33195 27.5933 3.33195 26.7856V14.8123H38.3688V21.2464C34.4877 21.8118 31.3131 24.5757 30.1418 28.2451H4.78359ZM39.8213 38.6611C35.8139 38.6611 32.5623 35.3899 32.5623 31.3595C32.5623 27.33 35.8139 24.0596 39.8213 24.0596C43.8287 24.0596 47.0803 27.3308 47.0803 31.3603C47.0803 35.3907 43.8287 38.6611 39.8213 38.6611Z"
          fill={color}
        />
        <path
          d="M7.68772 20.6527H11.3172C12.1205 20.6527 12.7689 20.0009 12.7689 19.1924C12.7689 18.3847 12.1213 17.7329 11.3172 17.7329H7.68772C6.88444 17.7329 6.23608 18.3847 6.23608 19.1924C6.23608 20.0001 6.88363 20.6527 7.6869 20.6527H7.68772ZM15.9148 22.4054H7.68772C6.88444 22.4054 6.23608 23.0572 6.23608 23.8649C6.23608 24.6726 6.88363 25.3252 7.6869 25.3252H15.9148C16.7181 25.3252 17.3664 24.6726 17.3664 23.8649C17.3664 23.0572 16.7181 22.4046 15.9148 22.4046V22.4054ZM43.5295 34.3488C43.5295 34.6113 43.4328 34.835 43.2394 35.0393C43.0459 35.234 42.8131 35.3317 42.5525 35.3317C42.291 35.3317 42.0492 35.2146 41.8361 34.9812L39.9681 32.9563L38.1099 34.9812C37.8869 35.2154 37.6451 35.3317 37.3935 35.3317C37.123 35.3317 36.8902 35.2348 36.6967 35.0393C36.6029 34.9479 36.5287 34.8388 36.4787 34.7185C36.4287 34.5981 36.4039 34.4691 36.4058 34.3391C36.4058 34.0854 36.5123 33.852 36.7254 33.6283L38.6713 31.584L36.8607 29.6859C36.6476 29.4622 36.541 29.2288 36.541 28.9752C36.541 28.703 36.6385 28.4688 36.832 28.2846C37.0254 28.0899 37.2582 27.9922 37.5189 27.9922C37.7902 27.9922 38.032 28.1093 38.2549 28.3428L39.9779 30.1924L41.7197 28.3428C41.9426 28.1085 42.1844 27.9922 42.4558 27.9922C42.7172 27.9922 42.9394 28.0891 43.1426 28.2846C43.3361 28.4784 43.4328 28.7127 43.4328 28.9752C43.4328 29.228 43.3262 29.4622 43.1131 29.6859L41.2844 31.584L43.2295 33.6275C43.423 33.852 43.5295 34.0854 43.5295 34.3488Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
