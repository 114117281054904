import React from 'react';
import { paymentMethodIdDictionary } from 'library/paymentMethodIdDicionary';
import { ManyPaymentOptionsContainer, Column, Title, Body, CardTitle, Card, FlagIcon, BankIcon } from './styles';
import PixIcon from 'assets/images/banks/pix.png'
import BoletoIcon from 'assets/images/banks/boleto.png'
import ItauIcon from 'assets/images/banks/itau.png'
import BBIcon from 'assets/images/banks/bb.png'
import BradescoIcon from 'assets/images/banks/bradesco.png'

export default function ManyPaymentOptions() {
  const flagsIDs = [3, 4, 5, 2, 20, 16]

  return <>
    <ManyPaymentOptionsContainer>
      <Column>
        <Title>
          Diversas opções de pagamento
        </Title>
        <Body>
          {`Ganhe mais tempo para o seu negócio! \n\nCom a Vindi você conta com os principais meios de pagamento e não precisa se preocupar com diferentes integrações, contratos e taxas.`}
        </Body>
      </Column>
      <Column>
        <CardTitle>
          Receba por cartão de crédito
        </CardTitle>
        <Card>
          {
            flagsIDs.map((e, i) => {
              return (
                <FlagIcon
                  src={paymentMethodIdDictionary[e].icon}
                  alt={paymentMethodIdDictionary[e].name}
                  key={i}
                />
              )
            })
          }
        </Card>
        <CardTitle>
          Receba por pix, boleto e TEF
        </CardTitle>
        <Card>
          <BankIcon
            src={PixIcon}
            alt="Pix"
          />
          <BankIcon
            src={BoletoIcon}
            alt="Boleto"
          />
          <BankIcon
            src={ItauIcon}
            alt="Itaú"
          />
          <BankIcon
            src={BBIcon}
            alt="Banco do Brasil"
          />
          <BankIcon
            src={BradescoIcon}
            alt="Bradesco"
          />
        </Card>
      </Column>
    </ManyPaymentOptionsContainer>
  </>;
}
