import { Button } from "@material-ui/core";
import shadows from "@material-ui/core/styles/shadows";
import styled from "styled-components";
import { buttonStyles, grayScale, primaryColors } from "theme/styleguide";

export const StickyHeaderContainer = styled.div`
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 60px;
  background: ${primaryColors.blue_5};
  ${shadows.drop_shadow_1}
  z-index: 1;
  ${({ $isVisibible }) => $isVisibible ? `
    display: flex;
    position: fixed;
    width: 100%;
  `: `
    display: none;
  `}
  @media (min-width: 768px)
  {
    min-height: 100px;
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.landing_install_app}
  @media (max-width: 767px)
  {
    max-width: 168px;
  }
  :disabled{
    background: ${primaryColors.gray_graph_2};
    color:${grayScale.gray_4}
  }
`