import React from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import { FooterContainer, StyledButton, Title } from './styles';

export default function Footer() {
  const windowWidth = useWindowWidth()
  const handleClick = () => {
    window.open("https://www.yapay.com.br/contato/", "_blank")
  }
  return <>
    <FooterContainer>
      <Title>
        {`Quer saber mais sobre a Vindi?${windowWidth >= 992 ? " " : "\n"}Fale com a gente!`}
      </Title>
      <StyledButton
        onClick={handleClick}
      >
        {windowWidth >= 992 ? "Quero falar com o comercial" : "Falar com o comercial"}
      </StyledButton>
    </FooterContainer>
  </>;
}
