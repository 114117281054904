import { Button } from "@material-ui/core";
import styled from "styled-components";
import { buttonStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${primaryColors.blue_5};
  padding: 32px;
  padding-bottom: 42px;
  @media (min-width: 992px)
  {
    padding: 50px;
    padding-bottom: 64px;
  }
`
export const Title = styled.span`
  ${textStyles.landing_600_14px}
  color: ${grayScale.white};
  margin-bottom: 32px;
  text-align: center;
  white-space: pre-wrap;
  @media (min-width: 992px)
  {
    ${textStyles.landing_600_18px}
    color: ${grayScale.white};
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.landing_footer}
`