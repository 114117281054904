import React from 'react';
import { accountVerificationDictionary } from 'library/accountVerificationDictionary';
import { getFormatedDate } from 'utils/getFormatedDate';
import { DocumentItemContainer, DocumentItemActionContainer, DocumentItemDescription, DocumentItemInnerContainer, DocumentItemTitle, DocumentStyledAlert, StyledButton, TitleContainer } from './styles';

export default function DocumentItem({
  documentKey,
  userData,
  reprovedReason,
  statusUpdateDate,
  handleNavigation,
  idIndexHelper = 0,
}) {
  const isBusinessPartner = !!userData.cnpj
  if (documentKey === "partnersId") {
    if (isBusinessPartner) {
      documentKey = "businessPartnerProofing"
    } else {
      documentKey = "personalPartnerId"
    }
  }

  return <>
    <DocumentItemContainer>
      {accountVerificationDictionary[documentKey].icon}
      <DocumentItemInnerContainer>
        <TitleContainer>
          {accountVerificationDictionary[documentKey].icon}
          <DocumentItemTitle>
            {
              accountVerificationDictionary[documentKey].title
            }
            {
              documentKey === "titularId" &&
              ` - ${userData.titularName}`
            }
            {
              ["businessPartnerProofing", "personalPartnerId"].includes(documentKey) &&
              ` - ${userData.name}`
            }
          </DocumentItemTitle>
        </TitleContainer>
        {
          !!reprovedReason &&
          <DocumentStyledAlert
            severity="error"
            icon={false}
          >
            <b>
              {`Motivo do reenvio:\n`}
            </b>
            <span>
              {reprovedReason}
            </span>
          </DocumentStyledAlert>
        }
        <DocumentItemActionContainer>
          <DocumentItemDescription>
            {
              !!statusUpdateDate ?
                `Este documento foi enviado ${getFormatedDate(statusUpdateDate, true, "em")}.` :
                accountVerificationDictionary[documentKey].description
            }
          </DocumentItemDescription>
          {
            !!handleNavigation &&
            <StyledButton
              onClick={handleNavigation}
              id={
                ["businessPartnerProofing", "personalPartnerId"].includes(documentKey) ?
                  `${accountVerificationDictionary[documentKey].buttonId}_${idIndexHelper}` :
                  accountVerificationDictionary[documentKey].buttonId
              }
            >
              Enviar
            </StyledButton>
          }
        </DocumentItemActionContainer>
      </DocumentItemInnerContainer>
    </DocumentItemContainer>
  </>;
}