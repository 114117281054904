import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const StickyAlertContainer = styled.div`
  min-height: 50px;
  @media (min-width: 768px)
  {
    min-height: 41px;
  }
`
export const StickyContainer = styled.div`
  position: fixed;
  width: 100%;
  min-height: 50px;
  z-index: 5;
  background: ${primaryColors.orange_alert};
  display: flex;
  align-items: center;
  @media (min-width: 768px)
  {
    min-height: 41px;
  }
`
export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2px;
  
  @media (min-width: 768px)
  {
    align-items: center;
  }
`
export const IconContainer = styled.span`
  margin-left:59px;
  line-height:normal;
`
export const MessageText = styled.span`
  ${textStyles.landing_600_16px};
  color:${grayScale.black};
  margin-left:8px;
`
export const StyledIconButton = styled(IconButton)`
  padding: 12px;
  i {
    display: flex;
    align-items: center;
    :hover {
      color: white !important;
    }
  }
  @media (max-width: 767px)
  {
    position: absolute;
    right: 0;
  }
`