import React from 'react';
import { CreditCardFormContainer, FeesInfoContainer, ParcelsOptionsContainer, TrayText14 } from './styles';
import PaymentIcon from 'assets/icons/PaymentIcon';
import FeesInfoCard from 'components/Onboarding/OnboardingSteps/shared/FeesInfoCard';
import ParcelsPicker from 'components/Onboarding/OnboardingSteps/shared/ParcelsPicker';
import LoadingIndicator from 'components/shared/LoadingIndicator';

export default function CreditCardForm({
  parcelsOptions = [],
  disabled = false,
  taxValue = "",
  isLoading = true,
  replySelectedParcels
}) {
  return <>
    {
      isLoading ?
        <LoadingIndicator /> :
        <CreditCardFormContainer>

          <ParcelsOptionsContainer>
            {
              parcelsOptions.map((e, i) => {
                return (
                  (Array.isArray(e.splits.split) && e.splits.split.length > 1) &&
                  <ParcelsPicker
                    replySelectedParcels={replySelectedParcels}
                    brandName={e.payment_method_name}
                    brandId={e.payment_method_id}
                    parcelOptions={e.splits.split}
                    name={`parcelValues.${i}`}
                    disabled={disabled}
                    key={i}
                  />
                )
              })
            }
          </ParcelsOptionsContainer>
          <TrayText14>
            Bandeiras com parcelamento em apenas 1x
          </TrayText14>
          <ParcelsOptionsContainer>
            {
              parcelsOptions.map((e, i) => {
                return (
                  (!Array.isArray(e.splits.split)) &&
                  <ParcelsPicker
                    replySelectedParcels={replySelectedParcels}
                    brandName={e.payment_method_name}
                    brandId={e.payment_method_id}
                    parcelOptions={e.splits.split}
                    name={`parcelValues.${i}`}
                    disabled={disabled}
                    key={i}
                  />
                )
              })
            }
          </ParcelsOptionsContainer>
          <FeesInfoContainer>
            <FeesInfoCard
              title="Juros de parcelamento"
              info={`${taxValue}% ao mês`}
              icon={<PaymentIcon />}
            />
          </FeesInfoContainer>
        </CreditCardFormContainer>
    }
  </>;
}