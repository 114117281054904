import { IconButton, Tooltip } from '@material-ui/core';
import AddCircleIcon from 'assets/icons/AddCircleIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import AlertDialog from 'components/shared/AlertDialog';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { Field, FieldArray } from 'formik';
import React, { useState } from 'react';
import { primaryColors } from 'theme/styleguide';
import { GAEventTracker } from 'utils/GAEventTracker';
import { InstructionContainer, InstructionsListManagerContainer, StyledButton, StyledTextField } from './styles';

export default function InstructionsListManager({
  values,
  touched,
  errors,
  disabled = false,
  isOnboardingStep = false
}) {
  const [isAlertOpen, setAlertOpen] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState()
  const [fieldArrayHelpers, setfieldArrayHelpers] = useState()
  const maxInstructionsQuantity = 9

  const handleOpenAlert = (arrayHelpers, index) => {
    setfieldArrayHelpers(arrayHelpers)
    setSelectedInstructionIndex(index)
    setAlertOpen(true)
  }
  const handleDismiss = () => {
    setAlertOpen(false)
  }
  const handleDelete = () => {
    fieldArrayHelpers.remove(selectedInstructionIndex)
    setAlertOpen(false)
    setSnackbarOpen(true)
  }

  return <>
    <InstructionsListManagerContainer>
      <FieldArray
        name="instructions"
        validateOnChange={false}
        render={arrayHelpers => (
          <>
            {
              values.instructions.map((_, i) => (
                <InstructionContainer key={i}>
                  <Field
                    as={StyledTextField}
                    name={`instructions.${i}`}
                    label={`Instrução ${1 + i}`}
                    variant="outlined"
                    size="small"
                    error={touched.instructions?.[i] && !!errors.instructions?.[i]}
                    helperText={touched.instructions?.[i] && errors.instructions?.[i]}
                    disabled={disabled}
                  />
                  <Tooltip
                    title="Excluir"
                    arrow
                  >
                    <IconButton
                      size="small"
                      onClick={() => { handleOpenAlert(arrayHelpers, i) }}
                      disabled={disabled}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </InstructionContainer>
              ))
            }
            <StyledButton
              variant="outlined"
              startIcon={
                <AddCircleIcon
                  color={
                    values.instructions.length >= maxInstructionsQuantity ?
                      primaryColors.blue_5 + "33" :
                      primaryColors.blue_5
                  }
                  size={0.8}
                />
              }
              onClick={() => {
                arrayHelpers.push('')
                GAEventTracker({
                  category: isOnboardingStep ? "Onboarding" : "Página configurações",
                  action: "Instruções do boleto",
                  label: "Adicionar uma instrução"
                })
              }}
              disabled={disabled || values.instructions.length >= maxInstructionsQuantity}
              id={
                isOnboardingStep ?
                  "tela_config_boleto_onboarding_botao_add_instrucao" :
                  "tela_config_boleto_dashboard_botao_add_instrucao"
              }
            >
              Adicionar instrução
            </StyledButton>
          </>
        )}
      />
    </InstructionsListManagerContainer>
    <AlertDialog
      description="Deseja realmente excluir esta instrução?"
      open={isAlertOpen}
      buttonsProps={[
        {
          title: "Excluir",
          function: handleDelete
        },
        {
          title: "Cancelar",
          function: handleDismiss
        }
      ]}
    />
    <SnackbarAlert
      isOpen={isSnackbarOpen}
      onClose={() => { setSnackbarOpen(false) }}
      message="Instrução excluída"
    />
  </>;
}