import React from 'react';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import useAddressProofingUploadSetup from 'components/FormsSetup/AccountVerification/useAddressProofingUploadSetup';
import { AddressProofingUploadStepContainer } from './styles';
import AddressProofingUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/AddressProofingUploadForm';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import LoadableButton from 'components/shared/LoadableButton';
import { Button } from '@material-ui/core';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function AddressProofingUploadStep() {
  const { accountVerificationStep, nextAccountVerificationStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit, addressData, documentRequired, documentStatus, isBusinessAccount } = useAddressProofingUploadSetup({
    afterSubmit: () => {
      const stepsCollectionLength = getCurrentOnboardingStepsCollection("documentsSendingSteps").length - 1
      if (accountVerificationStep < stepsCollectionLength) {
        nextAccountVerificationStep()
      } else {
        nextOnboardingSection()
      }
    },
  })
  return <>
    <AddressProofingUploadStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <AddressProofingUploadForm
                isLoading={isLoading}
                addressData={addressData}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                {...formikProps}
              />
              <SameSizeButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_envio_comprovante_endereco_onboarding_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={
                    isBusinessAccount ?
                      nextAccountVerificationStep :
                      nextOnboardingSection
                  }
                  id={"tela_envio_comprovante_endereco_onboarding_botao_enviar_depois"}
                >
                  Enviar depois
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </AddressProofingUploadStepContainer>
  </>;
}
