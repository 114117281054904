import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const ManyPaymentOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  padding-bottom: 0px;
  min-height: 438px;
  background: ${primaryColors.baby_blue};
  @media (min-width: 992px)
  {
    flex-direction: row;
    padding: 64px 120px;
    padding-bottom: 0px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 992px)
  {
    align-items: flex-start;
    :first-child{
      margin-right: 32px;
    }
  }
`
export const Title = styled.span`
  ${textStyles.landing_bold_18px}
  color: ${grayScale.black};
  margin-bottom: 28px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_bold_35px}
    max-width: 340px;
  }
`
export const Body = styled.span`
  ${textStyles.landing_400_14px}
  width: 100%;
  max-width: 440px;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 48px;
  @media (min-width: 992px)
  {
    text-align: start;
    margin-bottom: initial;
    ${textStyles.landing_400_16px}
  }
`
export const CardTitle = styled.span`
  ${textStyles.landing_700_14px}
  margin-bottom: 14px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_700_16px}
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  width: 100%;
  max-width: 440px;
  padding: 20px;
  background: ${grayScale.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-bottom: 38px;
  @media (min-width: 992px)
  {
    padding: 20px 28px;
    min-height: 115px;
    margin-bottom: 42px;
  }
`
export const FlagIcon = styled.img`
  height: 100%;
  max-height: 34px;
  :not(:first-child){
    margin-left: 8px;
  }
  @media (min-width: 992px)
  {
    max-height: 53px;
    :not(:first-child){
      margin-left: 12px;
    }
  }
`
export const BankIcon = styled.img`
  max-height: 28px;
  :not(:first-child){
    margin-left: 8px;
  }
  @media (min-width: 992px)
  {
    max-height: 41px;
    :not(:first-child){
      margin-left: 12px;
    }
  }
  @media (max-width: 991px)
  {
    :nth-child(1){
      max-width: 58px;
    }
    :nth-child(2){
      max-width: 44px;
    }
  }
`