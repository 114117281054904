import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchUsers = async () => {
  const access_token = getDataStorage('store_access_token')
  const dynamicBaseURL = getDataStorage('store_URL')
  return apiPrivate.get("/store_api/users", {
    params: {
      access_token,
      dynamicBaseURL,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}