import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { useOnboarding } from 'context/OnboardingContext';
import { DocumentsUploadInstructionsContainer, BodyText, Title, IntroductionColumn, IntroductionRow, StyledImage, ItemsRow, ItemContainer, ItemTitle, ItemBody, ItemInnerContainer, StyledList, StyledAlert, ListTitle } from './styles';
import UserFilesImage from 'assets/images/user-files.png'
import LocationIcon from 'assets/icons/LocationIcon';
import BusinessIcon from 'assets/icons/BusinessIcon';
import TaskIcon from 'assets/icons/TaskIcon';
import BadgeIcon from 'assets/icons/BadgeIcon';
import InfoBlackIcon from 'assets/icons/InfoBlackIcon';
import { primaryColors } from 'theme/styleguide';
import LoadingIndicator from 'components/shared/LoadingIndicator';

const InstructionItem = ({
  title = "",
  icon = <></>,
  children
}) => {
  return (
    <ItemContainer>
      {icon}
      <ItemInnerContainer>
        <ItemTitle>
          {title}
        </ItemTitle>
        <ItemBody>
          {children}
        </ItemBody>
      </ItemInnerContainer>
    </ItemContainer>
  )
}

export default function DocumentsUploadInstructions() {
  const { nextAccountVerificationStep, nextOnboardingSection, isBusinessAccount, isLoadingContext } = useOnboarding()

  return <>
    {
      isLoadingContext ?
        <LoadingIndicator /> :
        <DocumentsUploadInstructionsContainer>
          <IntroductionRow>
            <IntroductionColumn>
              <BodyText>
                {`Para validar seu cadastro, por favor envie alguns documentos da pessoa titular da conta e da empresa.`}
              </BodyText>
              <StyledAlert
                severity="info"
                icon={
                  <InfoBlackIcon
                    color={primaryColors.blue_3}
                  />
                }
              >
                <b>
                  {`Atenção:`}
                </b>
                {`  você poderá receber pagamentos na Vindi somente após estes documentos serem enviados e validados por nossa equipe.`}
              </StyledAlert>
            </IntroductionColumn>
            <StyledImage
              src={UserFilesImage}
              alt="Arquivos do usuário"
            />
          </IntroductionRow>
          <Title>
            Quais documentos precisa enviar?
          </Title>
          {
            isBusinessAccount ?
              <ItemsRow>
                <InstructionItem
                  title="Foto do RG ou CNH da pessoa titular da conta"
                  icon={<BadgeIcon />}
                >
                  {`Foto da frente e do verso do documento.`}
                </InstructionItem>
                <InstructionItem
                  title="Procuração"
                  icon={<TaskIcon />}
                >
                  <span>
                    Apenas se a pessoa titular da conta for procuradora da empresa.
                  </span>
                </InstructionItem>
                <InstructionItem
                  title="Documento de constituição da empresa"
                  icon={<BusinessIcon />}
                >
                  <ListTitle>
                    Documentos aceitos:
                  </ListTitle>
                  <StyledList>
                    <li>
                      Certificado de Cadastro de Micro Empresário Individual - para empresas que são <b>Micro Empresário Individual (MEI)</b>;
                    </li>
                    <li>
                      Requerimento de Empresário Individual - para empresas que são <b>Empresário Individual (EI)</b>;
                    </li>
                    <li>
                      Ato Constitutivo Eireli - para empresas que são <b>Empresa Individual de Responsabilidade Limitada (EIRELI)</b>;
                    </li>
                    <li>
                      Contrato Social - para empresas que são <b>Limitada (LTDA), Sociedade Simples (SS), Cooperativa (COOP) ou outros</b>;
                    </li>
                    <li>
                      Estatuto Social - para empresas que são <b>Sociedade Anônima (SA)</b>.
                    </li>
                  </StyledList>
                </InstructionItem>
                <InstructionItem
                  title="Comprovante de endereço"
                  icon={<LocationIcon />}
                >
                  <ListTitle>
                    Comprovantes aceitos:
                  </ListTitle>
                  <StyledList>
                    <li>
                      Contas de água, energia, internet, gás ou telefone;
                    </li>
                    <li>
                      Carnê de IPTU;
                    </li>
                    <li>
                      Fatura de condomínio;
                    </li>
                    <li>
                      Fatura do plano de saúde.
                    </li>
                  </StyledList>
                </InstructionItem>
              </ItemsRow> :
              <ItemsRow>
                <InstructionItem
                  title="Foto do RG ou CNH da pessoa titular da conta"
                  icon={<BadgeIcon />}
                >
                  {`Foto da frente e do verso do documento.`}
                </InstructionItem>

                <InstructionItem
                  title="Comprovante de endereço"
                  icon={<LocationIcon />}
                >
                  <ListTitle>
                    Comprovantes aceitos:
                  </ListTitle>
                  <StyledList>
                    <li>
                      Contas de água, energia, internet, gás ou telefone;
                    </li>
                    <li>
                      Carnê de IPTU;
                    </li>
                    <li>
                      Fatura de condomínio;
                    </li>
                    <li>
                      Fatura do plano de saúde.
                    </li>
                  </StyledList>
                </InstructionItem>
              </ItemsRow>
          }
          <BodyText
            $extraMarginBottom
          >
            Clique em “Continuar” para fazer o envio de cada documento separado.
          </BodyText>
          <ButtonsContainer>
            <Button
              onClick={nextAccountVerificationStep}
              id={"tela_envio_documentos_onboarding_botao_continuar"}
            >
              Continuar
            </Button>
            <Button
              variant="outlined"
              onClick={nextOnboardingSection}
              id={"tela_envio_documentos_onboarding_botao_enviar_depois"}
            >
              Enviar depois
            </Button>
          </ButtonsContainer>
        </DocumentsUploadInstructionsContainer>
    }
  </>;
}
