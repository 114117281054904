import React from 'react';
import UploadFileIcon from 'assets/icons/UploadFileIcon';
import { PendingDocumentsBannerContainer, BannerContent, BannerHeader, ContentContainer, ContentDescription, ContentTitle, HeaderSubtitle, HeaderTitle, StyledButton } from './styles';
import { useDashboard } from 'context/DashboardContext';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function PendingDocumentsBanner() {
  const { showPendingDocumentsBanner, setCurrentTab } = useDashboard()
  const { setCurrentMyAccountMenuItem } = useDashboardMyAccount()

  const handleNavigation = () => {
    setCurrentTab("myaccount")
    setCurrentMyAccountMenuItem("accountVerificationMenu")
  }

  return <>
    {
      showPendingDocumentsBanner &&
      <PendingDocumentsBannerContainer>
        <BannerHeader>
          <HeaderTitle>
            Falta pouco para você começar a receber pagamentos
          </HeaderTitle>
          <HeaderSubtitle>
            Após esta(s) etapa(s), sua conta Vindi estará pronta para receber pagamentos.
          </HeaderSubtitle>
        </BannerHeader>
        <BannerContent>
          <UploadFileIcon
            size={0.8}
            color="#00A3FF"
          />
          <ContentContainer>
            <ContentTitle>
              Enviar documentos
            </ContentTitle>
            <ContentDescription>
              Envie alguns documentos da pessoa titular da conta e da empresa para validar seu cadastro.
            </ContentDescription>
            <StyledButton
              onClick={handleNavigation}
            >
              Enviar documentos
            </StyledButton>
          </ContentContainer>
        </BannerContent>
      </PendingDocumentsBannerContainer>
    }
  </>;
}
