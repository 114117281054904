import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { CustomizeFormContainer, InstructionsColumn, ImageCard, ContentContainer, StyledButton, StyledList, StyledTextField, SubTitle, UploadedImage, ImageContainer, Content } from './styles';
import BoletoNameExemple from 'assets/images/nome-boleto.jpg'
import BoletoLogoExemple from 'assets/images/logo-boleto.jpg'
import AddCircleIcon from 'assets/icons/AddCircleIcon';
import { useFilePicker } from 'use-file-picker';
import DeleteIcon from 'assets/icons/DeleteIcon';
import { StepBody } from 'components/Onboarding/OnboardingBody/styles';
import { Field } from 'formik';
import AlertDialog from 'components/shared/AlertDialog';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';

export default function CustomizeForm({
  touched,
  errors,
  setFieldValue,
  values,
  disabled = false,
  isLoading = true,
}) {
  const [isAlertOpen, setAlertOpen] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isSnackbarOpenFileInvalid, setSnackbarOpenFileInvalid] = useState(false)
  const [openFileSelector, { filesContent, clear, errors: errorFile }] = useFilePicker({
    accept: [".jpg", ".png", ".gif"],
    multiple: false,
    readAs: "DataURL",
    maxFileSize: 2
  })

  const handleFilePicker = () => {
    openFileSelector()
  }
  const handleOpenAlert = () => {
    setAlertOpen(true)
  }
  const handleDelete = () => {
    clear()
    setFieldValue("logoImage", "")
    setAlertOpen(false)
    setSnackbarOpen(true)
  }
  const handleInvalidFile = useCallback(() => {
    clear()
    setFieldValue("logoImage", "")
    setAlertOpen(false)
    setSnackbarOpenFileInvalid(true)

  }, [clear, setFieldValue, setSnackbarOpenFileInvalid, setAlertOpen])

  const handleDismiss = () => {
    setAlertOpen(false)
  }
  const getTypeFileAccepted = useCallback((logoFile) => {
    return (logoFile.size < 2_097_152 && // 2_097_152 = 2mb
      (
        logoFile.type.includes("jpg") ||
        logoFile.type.includes("png") ||
        logoFile.type.includes("gif") ||
        logoFile.type.includes("jpeg")
      )
    )
  }, [])


  const handleUpdateFilesContent = useCallback(async () => {
    if (!!filesContent?.[0]?.content) {
      const logoFile = await parseDataURLtoFile(filesContent?.[0]?.content)
      if (getTypeFileAccepted(logoFile)) {
        setFieldValue("logoImage", filesContent?.[0]?.content)
      } else {
        handleInvalidFile()
      }
    }
  }, [filesContent, setFieldValue, getTypeFileAccepted])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleUpdateFilesContent()
  }, [filesContent, handleUpdateFilesContent])

  useEffect(() => {
    if (errorFile?.[0]?.fileSizeToolarge) {
      setSnackbarOpenFileInvalid(true)
    }
  }, [errorFile])

  return <>
    {
      isLoading ?
        <LoadingIndicator /> :
        <CustomizeFormContainer>
          <ContentContainer>
            <Content>
              <SubTitle>
                Nome da loja
              </SubTitle>
              <StepBody style={{ marginBottom: "20px" }}>
                Defina o nome da sua loja que será exibido nos boletos emitidos e na fatura do cartão de crédito dos compradores
              </StepBody>
              <Field
                as={StyledTextField}
                name="storeName"
                label="Nome da loja"
                error={touched.storeName && !!errors.storeName}
                helperText={touched.storeName && errors.storeName}
                size="small"
                disabled={disabled}
              />
            </Content>
            <ImageCard>
              <img
                src={BoletoNameExemple}
                alt="Exemplo de nome da loja no boleto"
              />
            </ImageCard>
          </ContentContainer>
          <ContentContainer>
            <InstructionsColumn>
              <SubTitle>
                Logo
              </SubTitle>
              <StepBody style={{ marginBottom: "20px" }}>
                Adicione seu logo para ser exibido nos boletos e e-mails de recuperação de venda
              </StepBody>
              <StyledList>
                <li>
                  Formatos permitidos: JPG, PNG e GIF com no máximo 2mb
                </li>
                <li>
                  Dimensões recomendadas: 120px de largura x 80px de altura
                </li>
              </StyledList>
              <ImageContainer>
                {
                  values["logoImage"]?.length > 0
                    || filesContent.length > 0 ?
                    <>
                      <UploadedImage
                        alt="Imagem de Logo"
                        src={values["logoImage"] || filesContent[0]?.content}
                      />
                      <Tooltip
                        title="Excluir"
                        arrow
                      >
                        <IconButton
                          size="small"
                          onClick={() => { handleOpenAlert() }}
                          disabled={disabled}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </> :
                    <StyledButton
                      variant="text"
                      startIcon={
                        <AddCircleIcon
                          color="#323A46"
                          size={0.64}
                        />
                      }
                      onClick={() => { handleFilePicker() }}
                      disabled={disabled}
                    >
                      Adicionar logo
                    </StyledButton>
                }
              </ImageContainer>
            </InstructionsColumn>
            <ImageCard>
              <img
                src={BoletoLogoExemple}
                alt="Exemplo de nome da loja no boleto"
              />
            </ImageCard>
          </ContentContainer>
        </CustomizeFormContainer>
    }
    <AlertDialog
      description="Deseja realmente excluir este logo?"
      open={isAlertOpen}
      buttonsProps={[
        {
          title: "Excluir",
          function: handleDelete
        },
        {
          title: "Cancelar",
          function: handleDismiss
        }
      ]}
    />
    <SnackbarAlert
      isOpen={isSnackbarOpen}
      onClose={() => { setSnackbarOpen(false) }}
      message="Logo excluída"
    />
    <SnackbarAlert
      isOpen={isSnackbarOpenFileInvalid}
      onClose={() => { setSnackbarOpenFileInvalid(false) }}
      message="Arquivo inválido"
      type="error"
    />
  </>;
}
