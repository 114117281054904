import React, { useEffect, useRef, useState } from 'react';
import { StyledAutocomplete } from './styles';
import { CircularProgress } from '@material-ui/core';
import { fetchGetMainActivities } from 'services/Account/GET/GetMainActivitiesService';
import { StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';

export default function MainActivityAutoComplete({
  label = "",
  values,
  error,
  helperText,
  onChange,
  setFieldValue = () => { },
  fullWidth = false,
  ...props
}) {
  const initialValues = {
    id: values.mainActivity,
    name: values.mainActivitySearchValue,
  }
  const [list, setList] = useState(
    !!values.mainActivitySearchValue ?
      [initialValues] :
      []
  )
  const [selectedOption, setSelectedOption] = useState(
    !!values.mainActivitySearchValue ?
      initialValues :
      null
  )
  const [searchValue, setSearchValue] = useState(values.mainActivitySearchValue)
  const [isSearching, setSearching] = useState(false)
  const mounted = useRef(false)

  const handleSelection = (i) => {
    if (!!i?.id) {
      setSelectedOption(i)
      setFieldValue("mainActivity", i.id)
      setList([i])
    }
  }

  useEffect(() => {
    setSearching(true)
    const delayDebounceFn = setTimeout(async () => {
      if (!!searchValue) {
        const res = await fetchGetMainActivities(searchValue)
        if (mounted.current) {
          setList(res?.data || [])
        }
      }
      setSearching(false)
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <StyledAutocomplete
      {...props}
      value={selectedOption}
      onChange={(_e, i) => { handleSelection(i) }}
      options={list}
      loading={isSearching}
      getOptionLabel={(option) => (option?.name || "")}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={
        (params) => (
          <StyledTextField
            {...params}
            label={label}
            placeholder="Digite aqui algumas palavras para buscar a atividade"
            size="small"
            onChange={(e) => { setSearchValue(e.target.value) }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {
                    isSearching &&
                    <CircularProgress color="inherit" size={20} />
                  }
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={error}
            helperText={
              helperText ||
              "Digite aqui algumas palavras para buscar a atividade. Ex: comércio de produtos artesanais..."
            }
            $fullWidth={fullWidth}
          />
        )
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.isDefaultMuiPrevented = true
        }
      }}
      autoComplete
      autoSelect
      disableClearable
      autoHighlight
      closeText="Fechar"
      openText="Abrir"
      loadingText="Carregando..."
      noOptionsText="Não encontrado"
      clearText="Limpar"
    />
  </>;
}
