import React, { useState } from 'react';
import { StyledMenu, StyledMenuItem } from './styles';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from 'assets/icons/MoreVertIcon';
import { dashboardTabsDictionary } from 'library/dashboardTabsDictionary';
import { useDashboard } from 'context/DashboardContext';
import { getDataStorage } from 'utils/storage';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function MobileDashboardHeader({
  showAccountVerificationMenu = false,
  handleOpenPortal = () => { },
  handleGAEventTracker = () => { },
}) {
  const { setCurrentTab } = useDashboard()
  const { setCurrentMyAccountMenuItem } = useDashboardMyAccount()
  const isMainUser = getDataStorage("main_user")
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSelectMenuItem = ({ tabKey }) => {
    handleGAEventTracker(tabKey)
    setCurrentTab(tabKey)
    handleCloseMenu()
  }

  const handleSelectSubMenuItem = ({ subMenuTabName, tabKey }) => {
    handleGAEventTracker(subMenuTabName)
    setCurrentTab(tabKey)
    setCurrentMyAccountMenuItem(subMenuTabName)
    handleCloseMenu()
  }

  return <>
    <IconButton
      id="menu-button"
      aria-controls={open ? 'opened-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      onClick={handleOpenMenu}
    >
      <MoreVertIcon />
    </IconButton>
    <StyledMenu
      id="opened-menu"
      aria-labelledby="menu-button"
      open={open}
      onClose={handleCloseMenu}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {
        Object.keys(dashboardTabsDictionary).map((tabKey, tabIndex) => {
          const shouldShowTab = (isMainUser || !dashboardTabsDictionary?.[tabKey]?.isMainUserOnlyTab)
          const hasSubMenuTabs = dashboardTabsDictionary[tabKey]?.subMenuTabs
          if (shouldShowTab) {
            if (!hasSubMenuTabs) {
              return (
                <StyledMenuItem
                  key={tabIndex}
                  onClick={() => { handleSelectMenuItem({ tabKey }) }}
                >
                  {dashboardTabsDictionary[tabKey].title}
                </StyledMenuItem>
              )
            } else {
              return (
                Object.keys(dashboardTabsDictionary[tabKey]?.subMenuTabs)?.map((subMenuTabName, subMenuTabIndex) => {
                  const shouldShowSubMenuTab = subMenuTabName !== "accountVerificationMenu" || showAccountVerificationMenu
                  if (shouldShowSubMenuTab) {
                    return (
                      <StyledMenuItem
                        onClick={() => { handleSelectSubMenuItem({ subMenuTabName, tabKey }) }}
                        key={`${tabIndex}${subMenuTabIndex}`}
                      >
                        {dashboardTabsDictionary[tabKey]?.subMenuTabs[subMenuTabName].title}
                      </StyledMenuItem>
                    )
                  } else {
                    return null
                  }
                })
              )
            }
          } else {
            return null
          }
        })
      }
      <StyledMenuItem
        onClick={() => {
          handleOpenPortal()
          handleCloseMenu()
        }}
      >
        Ir para portal Vindi
      </StyledMenuItem>
    </StyledMenu>
  </>;
}
