import { darken } from '@material-ui/core/styles';

export const primaryColors = {
  blue_1: "#234581",
  blue_5: "#235390",
  blue_3: "#2E7EE7",
  blue_X: "#236AC8",
  blue_Y: "#001A9E",
  ice_blue_3: "#14D4F4",
  baby_blue: "rgba(35, 106, 200, 0.1)",
  alert_blue: "rgba(46, 126, 231, 0.1)",
  yellow_3: "#FFC300",
  purple_3: "#A560EB",
  green_3: "#21C712",
  green_5: "#26A34B",
  red_balance: "#D33131",
  green_graph: "#34A853",
  yellow_graph: "#FBBC05",
  blue_graph: "#36A2EB",
  gray_graph: "#9C9C9C",
  gray_graph_2: "#546E7A",
  red_graph: "#EA4335",
  orange_graph: "#FF5427",
  orange_alert: "#FF9400"
};

export const grayScale = {
  black: "#1F1F1F",
  white: "#FFFFFF",
  gray_3: "#546E7A",
  gray_blend: "#546E7AB2",
  gray_20p100: "rgba(84, 110, 122, 0.2)",
  gray_60p100: "rgba(84, 110, 122, 0.6)",
  gray_4: "#98A8AF"
}

export const textStyles = {
  h3_24px: `
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: ${grayScale.black};
  `,
  h4_18px: `
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: ${grayScale.gray_3};
  `,
  h5_16px: `
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: ${grayScale.gray_3};
  `,
  h6_14px: `
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${grayScale.gray_3};
  `,
  subtitle_2_16px: `
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: ${grayScale.gray_3};
  `,
  subtitle_3_12px: `
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${grayScale.gray_3};
  `,
  body_1_14px: `
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${grayScale.gray_3};
  `,
  body_2_12px: `
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${grayScale.gray_3};
    letter-spacing: -0.04px;
  `,
  body_3_14px: `
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: ${grayScale.gray_3};
  `,
  tray_h2_30px: `
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0em;
  `,
  tray_h3_24px: `
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  `,
  tray_h4_18px: `
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  `,
  tray_h5_15px: `
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
  `,
  tray_h6_12px: `
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  `,
  tray_text_14px: `
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  `,
  tray_text_12px: `
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  `,
  landing_bold_40px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 40px;
    line-height: 126.5%;
    color: ${grayScale.white};
  `,
  landing_600_20px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 128.5%;
    color: ${grayScale.white};
  `,
  landing_400_16px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 132.5%;
    color: ${grayScale.gray_3};
  `,
  landing_400_14px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 132.5%;
    color: ${grayScale.gray_3};
  `,
  landing_700_24px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 126.5%;
    color: ${grayScale.white};
  `,
  landing_700_18px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 126.5%;
    color: ${grayScale.white};
  `,
  landing_700_16px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 128.5%;
    color: ${grayScale.gray_3};
  `,
  landing_700_14px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 128.5%;
    color: ${grayScale.gray_3};
  `,
  landing_600_16px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 128.5%;
    color: ${grayScale.white};
  `,
  landing_600_14px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 128.5%;
    color: ${grayScale.white};
  `,
  landing_bold_18px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 126.5%;
    color: ${primaryColors.blue_5};
  `,
  landing_bold_14px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 126.5%;
    color: ${primaryColors.blue_5};
  `,
  landing_bold_35px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 35px;
    line-height: 126.5%;
    color: ${grayScale.black};
  `,
  landing_bold_16px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 128.5%;
    color: ${grayScale.gray_3};
  `,
  landing_600_18px: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 128.5%;
    color: ${grayScale.gray_3};
  `,
}

export const shadowStyles = {
  drop_shadow_1: `
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  `,
  drop_shadow_2: `
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1);
  `,
  drop_shadow_3: `
    border: 1px solid rgba(84, 110, 122, 0.3);
  `,
  drop_shadow_4: `
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  `,
  drop_shadow_5: `
    box-shadow: 0px 4px 20px rgba(1, 33, 105, 0.08);
  `,
  drop_shadow_6: `
    box-shadow: 0px 4px 30px rgba(1, 33, 105, 0.18);
  `
}

export const borderStyles = {
  border_1: `
    border: 1px solid ${grayScale.gray_20p100};
    border-radius: 4px;
  `,
  border_2: `
    border: 1px solid rgba(84, 110, 122, 0.3);
    border-radius: 4px;
  `
}

export const buttonStyles = {
  yapay_grayed_add: `
    font-weight: 700;
    font-size: 13px;
    color: #323A46;
    min-width: 144px;
    min-height: 37px;
    padding-left: 12px;
    padding-right: 12px;
    background: #DEE2E6;
    :hover{
      background: ${darken("#DEE2E6", .1)};
    }
  `,
  yapay_bluish_add: `
    font-size: 13px;
    font-weight: 600;
    color: ${primaryColors.blue_5};
    min-width: 144px;
    min-height: 37px;
    padding-left: 12px;
    padding-right: 12px;
  `,
  landing_install_app: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    max-width: 256px;
    min-height: 36px;
    color: ${grayScale.white};
    text-transform: uppercase;
    background: ${primaryColors.green_5};
    :hover{
      background: ${darken(primaryColors.green_5, .1)};
    }
    @media (min-width: 992px)
    {
      max-width: 322px;
      min-height: 49px;
      font-size: 16px;
    }
  `,
  landing_footer: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    max-width: 256px;
    min-height: 36px;
    color: ${primaryColors.blue_5};
    text-transform: uppercase;
    background: ${grayScale.white};
    :hover{
      background: ${darken(grayScale.white, .1)};
    }
    @media (min-width: 992px)
    {
      max-width: 322px;
      min-height: 49px;
      font-size: 16px;
    }
  `,
}