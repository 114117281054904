import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const AboutYapayContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 16px;
  @media (min-width: 992px)
  {
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 120px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 992px)
  {
    align-items: initial;
  }
`
export const StyledImage = styled.img`
  margin: auto;
  max-width: 100%;
  padding: 0px 60px;
  margin-bottom: 24px;
  @media (min-width: 992px)
  {
    padding: initial;
    margin-bottom: initial;
  }
`
export const Subtitle = styled.span`
  width: fit-content;
  ${textStyles.landing_bold_14px}
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 14px;
  border-bottom: 2px solid ${primaryColors.ice_blue_3};
  margin: auto;
  margin-bottom: 24px;
  @media (min-width: 992px)
  {
    text-align: start;
    margin: initial;
    margin-bottom: 56px;
    ${textStyles.landing_bold_18px}
  }
`
export const Title = styled.span`
  ${textStyles.landing_bold_18px}
  color: ${grayScale.black};
  text-align: center;
  max-width: 295px;
  margin-bottom: 16px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_bold_35px}
    max-width: 320px;
    margin-bottom: 42px;
    text-align: initial;
  }
`
export const Body = styled.span`
  ${textStyles.landing_400_14px}
  text-align: center;
  @media (min-width: 992px)
  {
    ${textStyles.landing_400_16px}
    text-align: initial;
  }
`
export const StyledList = styled.ul`
  padding-left: 24px;
  
`
export const ListItem = styled.li`
  ${textStyles.landing_400_14px}
  margin-bottom: 8px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_400_16px}
    text-align: initial;
    margin-bottom: initial;
  }
`