import React, { useCallback, useEffect, useRef, useState } from 'react';
import AccountVerificationImage from 'assets/images/account-verification.png'
import { AccountVerificationMenuContainer, BottomInstructions, DocumentsListTitle, Instructions, InstructionsContainer, UserDataContainer, UserIdentifier, UserName, DocumentList } from './styles';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { parseStringToCPF } from 'utils/parseStringToCPF';
import { parseStringToCNPJ } from 'utils/parseStringToCNPJ';
import { fetchGetBusinessPartnersList } from 'services/BusinessPartners/GET/GetBusinessPartnersListService';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { fetchGetPartnersDocumentStatus } from 'services/Document/GET/GetPartnersDocumentStatusService';
import { accountVerificationVariantsDictionary } from 'library/accountVerificationVariantsDictionary';
import DocumentItem from 'components/Dashboard/AccountVerificationMenu/DocumentItem';
import { findPartnerStatus } from 'utils/findPartnerStatus';
import { getAccountVerificationMenuVariant } from 'utils/getAccountVerificationMenuVariant';

export default function AccountVerificationMenu() {
  const mounted = useRef(false)
  const { setCurrentAccountVerificationMenuItem, setSelectedPartnerId } = useDashboardMyAccount()
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [documentsStatus, setDocumentsStatus] = useState([])
  const [submitedDocumentsKeys, setSubmitedDocumentsKeys] = useState([])
  const [pendingSubmissionDocumentsKeys, setPendingSubmissionDocumentsKeys] = useState([])
  const [rejectedDocumentsKeys, setRejectedDocumentsKeys] = useState([])
  const [partners, setPartners] = useState([])
  const [partnersDocuments, setPartnersDocuments] = useState([])

  const handleOpenForm = (documentKey) => {
    setCurrentAccountVerificationMenuItem(documentKey)
  }

  const handlePartnerSelection = (partnerData) => {
    const isBusinessPartner = !!partnerData.cnpj
    setSelectedPartnerId(partnerData.id)
    if (isBusinessPartner) {
      handleOpenForm("businessPartnerProofing")
    } else {
      handleOpenForm("personalPartnerId")
    }
  }

  const getAccountVerificationMenuData = useCallback(async () => {
    const [
      { type },
      { name, cpf, social_name, cnpj, account_type, verification_status, account_register },
      fetchedTitularPartner,
      document,
      address,
      company,
      selfie,
      // bank_account,
      partnersData,
    ] = await Promise.all([
      fetchGetAccount(),
      fetchGetAccountMyAccount(),
      fetchGetBusinessPartnersList(true),
      fetchGetDocumentStatus("document"),
      fetchGetDocumentStatus("address"),
      fetchGetDocumentStatus("company"),
      fetchGetDocumentStatus("selfie"),
      // fetchGetDocumentStatus("bank_account"),
      fetchGetBusinessPartnersList()
    ])
    const personalPartnersIdList = partnersData.filter((e) => !e?.cnpj).map((e) => e.id)
    const businessPartnersIdList = partnersData.filter((e) => !!e?.cnpj).map((e) => e.id)
    const [
      document_partner,
      company_partner,
    ] = await Promise.all([
      fetchGetPartnersDocumentStatus("document_partner", personalPartnersIdList),
      fetchGetPartnersDocumentStatus("company_partner", businessPartnersIdList),
    ])
    const accountVerificationMenuVariant = getAccountVerificationMenuVariant({
      account_type,
      account_register,
      verification_status,
    })
    const filtredRejectedDocumentsKeys = []
    const filtredPendingSubmissionDocumentsKeys = []
    const filtredSubmitedDocumentsKeys = []
    const filtredDocumentsStatus = {}
    const allTitularDocumentsStatus = {
      titularId: document,
      titularSelfie: selfie,
      addressProofing: address,
      businessProofing: company,
      // bankProofing: bank_account,
    }
    const allPartnersDocumentsStatus = [...document_partner, ...company_partner]

    accountVerificationVariantsDictionary[type][accountVerificationMenuVariant].forEach((e) => {
      if (e === "partnersId") {
        let idIndexHelper = 1
        allPartnersDocumentsStatus.forEach((e) => {
          const itemStatus = e.status.tag
          if (itemStatus === "rejected") {
            e.idIndexHelper = idIndexHelper
            idIndexHelper++
            if (!filtredRejectedDocumentsKeys.includes("partnersId")) {
              filtredRejectedDocumentsKeys.push("partnersId")
            }
          } else if (itemStatus === "unsent") {
            e.idIndexHelper = idIndexHelper
            idIndexHelper++
            if (!filtredPendingSubmissionDocumentsKeys.includes("partnersId")) {
              filtredPendingSubmissionDocumentsKeys.push("partnersId")
            }
          } else if ((itemStatus === "approved" || itemStatus === "pending") && !filtredSubmitedDocumentsKeys.includes("partnersId")) {
            filtredSubmitedDocumentsKeys.push("partnersId")
          }
        })
      } else {
        const documentItem = allTitularDocumentsStatus[e]
        const itemStatus = documentItem.status.tag
        filtredDocumentsStatus[e] = {
          status: documentItem.status.tag,
        }
        if (itemStatus === "rejected") {
          filtredRejectedDocumentsKeys.push(e)
          filtredDocumentsStatus[e] = {
            statusUpdateDate: documentItem?.status_changed_at || documentItem?.created_at,
            reprovedReason: documentItem?.reason,
          }
        } else if (itemStatus === "unsent") {
          filtredPendingSubmissionDocumentsKeys.push(e)
        } else if (itemStatus === "approved" || itemStatus === "pending") {
          filtredSubmitedDocumentsKeys.push(e)
          filtredDocumentsStatus[e] = {
            statusUpdateDate: documentItem?.created_at || documentItem?.status_changed_at,
          }
        }
      }
    })

    if (mounted.current) {
      setBusinessAccount(type === "business")
      if (type === "business") {
        setUserData({
          name: social_name,
          id: parseStringToCNPJ(cnpj),
          titularName: fetchedTitularPartner.name,
        })
      } else {
        setUserData({
          name,
          id: parseStringToCPF(cpf),
          titularName: name,
        })
      }
      setDocumentsStatus(filtredDocumentsStatus)
      setRejectedDocumentsKeys(filtredRejectedDocumentsKeys)
      setPendingSubmissionDocumentsKeys(filtredPendingSubmissionDocumentsKeys)
      setSubmitedDocumentsKeys(filtredSubmitedDocumentsKeys)
      setPartners(partnersData)
      setPartnersDocuments(allPartnersDocumentsStatus)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getAccountVerificationMenuData()
  }, [getAccountVerificationMenuData])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <AccountVerificationMenuContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <InstructionsContainer>
              <Instructions>
                {`Para utilizar alguns recursos da sua conta Vindi, é necessário enviar alguns documentos.\n\n\nEste envio serve como comprovação de dados e atende as exigências de órgãos reguladores para que suas movimentações de valores na Vindi sejam ainda mais seguras.`}
              </Instructions>
              <img src={AccountVerificationImage} alt="Envio de documentos" />
            </InstructionsContainer>
            <UserDataContainer>
              <UserName>
                {userData.name}
              </UserName>
              <UserIdentifier>
                {isBusinessAccount ? "CNPJ" : "CPF"}: {userData.id}
              </UserIdentifier>
            </UserDataContainer>
            {
              !!rejectedDocumentsKeys.length &&
              <>
                <DocumentsListTitle>
                  Documentos que precisam ser reenviados
                </DocumentsListTitle>
                <DocumentList>
                  {
                    rejectedDocumentsKeys.map((e, i) => {
                      if (e === "partnersId") {
                        return partners.map((partnerData, j) => {
                          const partnerStatus = findPartnerStatus(partnerData, partnersDocuments)
                          if (partnerStatus.status.tag === "rejected") {
                            return (
                              <DocumentItem
                                documentKey={e}
                                userData={partnerData}
                                reprovedReason={partnerStatus.reason}
                                handleNavigation={() => {
                                  handlePartnerSelection(partnerData)
                                }}
                                key={`${i}${j}`}
                                idIndexHelper={partnerStatus.idIndexHelper}
                              />
                            )
                          } else {
                            return null
                          }
                        })
                      } else {
                        return (
                          <DocumentItem
                            documentKey={e}
                            userData={userData}
                            reprovedReason={documentsStatus[e]?.reprovedReason}
                            handleNavigation={() => {
                              handleOpenForm(e)
                            }}
                            key={i}
                          />
                        )
                      }
                    })
                  }
                </DocumentList>
              </>
            }
            {
              !!pendingSubmissionDocumentsKeys.length &&
              <>
                <DocumentsListTitle>
                  Documentos que precisam ser enviados
                </DocumentsListTitle>
                <DocumentList>
                  {
                    pendingSubmissionDocumentsKeys.map((e, i) => {
                      if (e === "partnersId") {
                        return partners.map((partnerData, j) => {
                          const partnerStatus = findPartnerStatus(partnerData, partnersDocuments)
                          if (partnerStatus.status.tag === "unsent") {
                            return (
                              <DocumentItem
                                documentKey={e}
                                userData={partnerData}
                                reprovedReason={partnerStatus.reason}
                                handleNavigation={() => {
                                  handlePartnerSelection(partnerData)
                                }}
                                key={`${i}${j}`}
                                idIndexHelper={partnerStatus.idIndexHelper}
                              />
                            )
                          } else {
                            return null
                          }
                        })
                      } else {
                        return (
                          <DocumentItem
                            documentKey={e}
                            userData={userData}
                            reprovedReason={documentsStatus[e]?.reprovedReason}
                            handleNavigation={() => {
                              handleOpenForm(e)
                            }}
                            key={i}
                          />
                        )
                      }
                    })
                  }
                </DocumentList>
              </>
            }
            {
              !!submitedDocumentsKeys.length &&
              <>
                <DocumentsListTitle>
                  Documentos enviados
                </DocumentsListTitle>
                <DocumentList>
                  {
                    submitedDocumentsKeys.map((e, i) => {
                      if (e === "partnersId") {
                        return partners.map((partnerData, j) => {
                          const partnerStatus = findPartnerStatus(partnerData, partnersDocuments)
                          if (partnerStatus.status.tag === "approved" || partnerStatus.status.tag === "pending") {
                            return (
                              <DocumentItem
                                documentKey={e}
                                userData={partnerData}
                                reprovedReason={partnerStatus.reason}
                                statusUpdateDate={partnerStatus?.created_at || partnerStatus?.status_changed_at}
                                key={`${i}${j}`}
                              />
                            )
                          } else {
                            return null
                          }
                        })
                      } else {
                        return (
                          <DocumentItem
                            documentKey={e}
                            userData={userData}
                            reprovedReason={documentsStatus[e]?.reprovedReason}
                            statusUpdateDate={documentsStatus[e]?.statusUpdateDate}
                            key={i}
                          />
                        )
                      }
                    })
                  }
                </DocumentList>
              </>
            }
            <BottomInstructions>
              {`Os recursos da conta Vindi estarão disponíveis para utilização após todos os documentos serem enviados e verificados.\nA verificação pode ocorrer em até 7 dias úteis após o último documento ser enviado.`}
            </BottomInstructions>
          </>
      }
    </AccountVerificationMenuContainer>
  </>;
}
