import React from 'react';
import { Form, Formik } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import LoadableButton from 'components/shared/LoadableButton';
import { StyledButtonsContainer } from 'components/Dashboard/AccountDataMenu/styles';
import { Button, Collapse } from '@material-ui/core';
import { PersonalAccountTitularPersonFormContainer } from './styles';
import PersonalAccountTitularPersonFields from 'fields/Dashboard/MyAccountTab/AccountDataMenu/PersonalAccount/PersonalAccountTitularPersonFields/PersonalAccountTitularPersonFields';
import usePersonalAccountTitularPersonDataSetup from 'setup/Dashboard/MyAccountTab/AccountDataMenu/PersonalAccount/usePersonalAccountTitularPersonDataSetup';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function PersonalAccountTitularPersonForm() {
  const { isAccountDataMenuFormsDisabled, toggleAccountDataMenuFormsDisabled } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen, hasDigitalAccount } = usePersonalAccountTitularPersonDataSetup({
    afterSubmit: () => {
      toggleAccountDataMenuFormsDisabled()
    },
    reloadAfterSubmit: true,
  })
  const handleCancel = (formikProps) => {
    formikProps.resetForm()
    toggleAccountDataMenuFormsDisabled()
  }
  return <>
    <PersonalAccountTitularPersonFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PersonalAccountTitularPersonFields
                {...formikProps}
                isLoading={isLoading}
                disabledForm={isAccountDataMenuFormsDisabled}
                hasDigitalAccount={hasDigitalAccount}
              />
              <Collapse
                in={!isAccountDataMenuFormsDisabled}
              >
                <StyledButtonsContainer>
                  <LoadableButton
                    type="submit"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                    isLoading={formikProps.isSubmitting}
                  >
                    Salvar
                  </LoadableButton>
                  <Button
                    variant="outlined"
                    onClick={() => { handleCancel(formikProps) }}
                    disabled={formikProps.isSubmitting || isLoading}
                  >
                    Cancelar
                  </Button>
                </StyledButtonsContainer>
              </Collapse>
            </Form>
          )
        }
      </Formik>
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Salvo"
      />
    </PersonalAccountTitularPersonFormContainer>
  </>;
}