import React from 'react';
import { Form, Formik } from 'formik';
import { UserIdUploadMenuItemContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import useUserIdUploadSetup from 'components/FormsSetup/AccountVerification/useUserIdUploadSetup';
import UserIdUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/UserIdUploadForm';
import { StyledLoadableButton } from 'components/Dashboard/DashboardAccountVerification/styles';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function UserIdUploadMenuItem() {
  const { goBackToAccountVerificationMenu } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, userData, documentRequired, documentStatus, isBusinessAccount } = useUserIdUploadSetup({
    afterSubmit: goBackToAccountVerificationMenu,
  })
  return <>
    <UserIdUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserIdUploadForm
                userData={userData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                isBusinessAccount={isBusinessAccount}
                {...formikProps}
              />
              <ButtonsContainer>
                <StyledLoadableButton
                  type="submit"
                  disabled={
                    (documentRequired && !formikProps.isValid) ||
                    (isLoading || formikProps.isSubmitting)
                  }
                  isLoading={formikProps.isSubmitting}
                >
                  {
                    ["approved", "pending"].includes(documentStatus?.status?.tag) ?
                      "Continuar" :
                      "Enviar"
                  }
                </StyledLoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </UserIdUploadMenuItemContainer>
  </>;
}
