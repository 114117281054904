import React from 'react';
import { DashboardMyAccountContainer } from './styles';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import AccountDataProvider from 'context/AccountDataContext';
import { myAccountDictionary } from 'library/myAccountDictionary';

export default function DashboardMyAccount() {
  const { currentMyAccountMenuItem } = useDashboardMyAccount()

  return <>
    <AccountDataProvider>
      <DashboardMyAccountContainer>
        {
          myAccountDictionary[currentMyAccountMenuItem].component
        }
      </DashboardMyAccountContainer>
    </AccountDataProvider>
  </>;
}
