import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { fetchUploadAddress } from 'services/Document/POST/UploadAddressService';
import { fetchGetAddress } from 'services/Address/GET/GetAddressService';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function useAddressProofingUploadSetup({
  afterSubmit = () => { },
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [addressData, setAddressData] = useState({})
  const [documentRequired, setDocumentRequired] = useState(false)
  const [documentStatus, setDocumentStatus] = useState()
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const [initialValues] = useState({
    adressProof: ""
  })
  const validationSchema = object({
    adressProof: string()
      .required()
  })

  const fetchAccountData = useCallback(async () => {
    const address = await fetchGetAddress()
    const { type: accountType } = await fetchGetAccount()
    setBusinessAccount(accountType === "business")
    if (mounted.current) {
      setAddressData(address)
      setLoading(false)
    }
  }, [])

  const getDocumentStatus = useCallback(async () => {
    fetchGetDocumentStatus("address")
      .then((data) => {
        if (mounted.current) {
          setDocumentStatus(data)
          if (["pending", "approved"].includes(data?.status?.tag)) {
            setDocumentRequired(false);
          } else {
            setDocumentRequired(true)
          }
        }
      })
  }, [])

  const handleSubmit = async ({
    adressProof
  }) => {
    if (adressProof) {
      const fileAdressProof = await parseDataURLtoFile(adressProof, "address")
      await fetchUploadAddress(fileAdressProof)
        .then((res) => {
          if (res?.status === 200) {
            GAEventTracker({
              category: "Onboarding",
              action: "Anexar comprovante de endereço",
              label: "Anexar o comprovante de endereço e salvar"
            })
            afterSubmit()
          }
        })
    } else {
      afterSubmit()
    }
  }

  useEffect(() => {
    fetchAccountData()
    getDocumentStatus()
  }, [fetchAccountData, getDocumentStatus])


  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    addressData,
    documentRequired,
    documentStatus,
    isBusinessAccount,
  })
}