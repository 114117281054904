import apiPublic from 'services/apiPublic'
import { getDataStorage, setDataStorage } from 'utils/storage'

export const fetchGetCodePermissioned = async () => {
  const store_url = getDataStorage('store_URL')
  const store_access_token = getDataStorage('store_access_token')

  return apiPublic.post("/v1/authorizations/code_with_token", {}, {
    headers: {
      store_url,
      store_access_token
    }
  })
    .then(({ data }) => {
      if (data?.data_response?.authorization?.code) {
        setDataStorage("yapay_permissioned_code", data?.data_response?.authorization?.code)
        return data?.data_response?.authorization?.code
      } else {
        return null
      }
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}