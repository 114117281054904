import React from 'react';
import NotFoundArtImage from 'assets/images/not-found-art.png'
import { EmptyPartnersListContainer, Title, StyledImage } from './styles';
import { Button } from '@material-ui/core';

export default function EmptyPartnersList({
  receivedFuntion = () => { },
}) {
  return <>
    <EmptyPartnersListContainer>
      <StyledImage
        src={NotFoundArtImage}
      />
      <Title>
        Não há pessoas sócias cadastradas.
      </Title>
      <Button
        onClick={receivedFuntion}
      >
        Cadastrar pessoas sócias
      </Button>
    </EmptyPartnersListContainer>
  </>;
}
