import { useCallback, useEffect, useState } from 'react';
import { object, number } from 'yup';
import { useRef } from 'react';
import { useAccountData } from 'context/AccountDataContext';
import { fetchValidateSmsPhone } from 'services/Account/POST/ValidateSmsPhoneService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function usePhoneSMSVerificationSetup({
  afterSubmit = () => { },
  errorSubmit = () => { }
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const { messageIdSMSPhone } = useAccountData()
  const [initialValues, setInitialValues] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
  })
  const validationSchema = object({
    code1: number(),
    code2: number(),
    code3: number(),
    code4: number(),
  })

  const fetchAccountData = useCallback(async () => {
    if (mounted.current) {
      setInitialValues({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
      });
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    code1, code2, code3, code4
  }) => {
    await fetchValidateSmsPhone(`${code1}${code2}${code3}${code4}`, messageIdSMSPhone)
      .then(({ data }) => {
        if (data.success) {
          afterSubmit()
          GAEventTracker({
            category: "Onboarding",
            action: "Verificar celular",
            label: "Conclusão da validação de celular"
          })
        } else {
          errorSubmit()
        }
      })
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
  })
}