import apiPublic from 'services/apiPublic'
import { getDataStorage } from 'utils/storage'

export const fetchBondAccount = async () => {
  const store_url = getDataStorage('store_URL')
  const store_access_token = getDataStorage('store_access_token')
  const yapay_code = getDataStorage('yapay_code')

  return apiPublic.get("/bond/account", {
    headers: {
      store_url,
      store_access_token,
      yapay_code
    }
  })
    .then(({ data }) => {
      return data.hasMatched
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}