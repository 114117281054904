import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetMainActivities = async (name = "") => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/account/main_activities", {
    params: {
      name,
    },
    headers: {
      code
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}