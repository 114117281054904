import DevicesIcon from 'assets/icons/DevicesIcon';
import SecurityIcon from 'assets/images/passwordi-icon.png';
import ShoppingCartV2Icon from 'assets/icons/ShoppingCartV2Icon';
import React from 'react';
import { AppBenefitsContainer, BenefitDescription, BenefitItem, IconContainer } from './styles';

const benefits = [
  {
    description: "Mais comodidade: tenha as principais informações da Vindi no mesmo painel da loja",
    icon: <DevicesIcon />
  },
  {
    description: "Mais praticidade: não é preciso informar sua senha a cada novo acesso",
    icon: <img src={SecurityIcon} alt="Security Icon" />
  },
  {
    description: "Acesse as últimas transações, status das vendas e próximos valores que serão creditados em sua conta",
    icon: <ShoppingCartV2Icon />
  }
]

export default function AppBenefits() {
  return <>
    <AppBenefitsContainer>
      {
        benefits.map((e, i) => {
          return (
            <BenefitItem key={i}>
              <IconContainer>
                {e.icon}
              </IconContainer>
              <BenefitDescription>
                {e.description}
              </BenefitDescription>
            </BenefitItem>
          )
        })
      }
    </AppBenefitsContainer>
  </>;
}
