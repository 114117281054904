import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchGetConstitution } from 'services/Account/GET/GetConstitutionService';
import { fetchUpdateAccountData } from 'services/Account/POST/UpdateAccountData';
import { fetchGetAddress } from 'services/Address/GET/GetAddressService';
import { fetchGetLegalNatureList } from 'services/Account/GET/GetLegalNatureListService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { formatIdentifier } from 'utils/formatIdentifier';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { validateIdentifier } from 'utils/validateIdentifier';
import { object, string, date, number, array } from 'yup';
import { fetchPhoneList } from 'services/Phone/GET/PhoneListService';
import { fetchPhoneDelete } from 'services/Phone/POST/PhoneDeleteService';
import { fetchPhoneCreate } from 'services/Phone/POST/PhoneCreateService';
import { fetchPhoneUpdate } from 'services/Phone/POST/PhoneUpdateService';

export default function useEnterpriseDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [constitutionList, setConstitutionList] = useState([])
  const [legalNatureList, setLegalNatureList] = useState([])
  const [hasDigitalAccount, setHasDigitalAccount] = useState(true)

  const fetchData = useCallback(async () => {
    const fetchedMyAccount = await fetchGetAccountMyAccount()
    const fetchedTelephoneList = await fetchPhoneList()
    const fetchedAdress = await fetchGetAddress()
    const fetchedConstitutionList = await fetchGetConstitution()
    const fetchedLegalNatureList = await fetchGetLegalNatureList()

    const validationObject = {
      socialName: string()
        .required("Este campo é obrigatório"),
      CNPJ: string()
        .test('valid-identifier', 'O CNPJ informado é inválido', validateIdentifier)
        .required("Este campo é obrigatório"),
      fantasyName: string()
        .required("Este campo é obrigatório"),
      telephoneList: array()
        .of(
          object()
            .shape({
              phoneNumber: string()
                .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
                .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
                .required('Este campo deve ser preenchido ou excluído'),
              phoneType: string()
                .required("Este campo é obrigatório"),
            })
        ),
      excludedTelephoneList: array(),
      address: object()
        .shape({
          street: string(),
          number: string(),
          complement: string(),
          neighborhood: string(),
          city: string(),
          state: string(),
          zipCode: string()
            .min(9, 'O CEP informado é inválido'),
        }),
      ...(fetchedMyAccount?.use_digital_account && {
        legalNature: string()
          .required('Este campo é obrigatório'),
        constitutionForm: string()
          .required('Este campo é obrigatório'),
        formationDate: date()
          .nullable()
          .typeError("Informe uma data válida"),
        mainActivity: string()
          .required('Este campo é obrigatório'),
        mainActivitySearchValue: string(),
        monthlyRevenue: number()
          .required('Este campo é obrigatório'),
      }),
    }

    const initialValuesObject = {
      socialName: fetchedMyAccount?.social_name || "",
      CNPJ: formatIdentifier(fetchedMyAccount?.cnpj),
      fantasyName: fetchedMyAccount?.fantasy_name || "",
      telephoneList: fetchedTelephoneList || [{
        phoneType: "business",
        phoneNumber: "",
        isNewRegister: true,
        isUpdatedRegister: false,
      }],
      excludedTelephoneList: [],
      address: {
        street: fetchedAdress?.street || "",
        number: fetchedAdress?.number || "",
        complement: fetchedAdress?.complement || "",
        neighborhood: fetchedAdress?.neighborhood || "",
        city: fetchedAdress?.city || "",
        state: fetchedAdress?.state || "",
        zipCode: parseStringToCEP(fetchedAdress?.zipcode || ""),
      },
      hasDigitalAccount: fetchedMyAccount?.use_digital_account,
      ...(fetchedMyAccount?.use_digital_account && {
        legalNature: fetchedMyAccount?.legal_nature?.id || "",
        constitutionForm: fetchedMyAccount?.constitution_form?.id || "",
        formationDate: fetchedMyAccount?.formation_date?.replace(/-/g, '/') || null,
        mainActivity: fetchedMyAccount?.main_activity?.id || "",
        mainActivitySearchValue: fetchedMyAccount?.main_activity?.name || "",
        monthlyRevenue: parseFloat(fetchedMyAccount?.monthly_revenue || 0),
      }),
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setConstitutionList(fetchedConstitutionList)
      setLegalNatureList(fetchedLegalNatureList)
      setHasDigitalAccount(fetchedMyAccount?.use_digital_account)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    socialName,
    fantasyName,
    legalNature,
    constitutionForm,
    formationDate,
    mainActivity,
    monthlyRevenue,
    telephoneList,
    excludedTelephoneList,
    hasDigitalAccount,
  }) => {
    if (excludedTelephoneList?.length > 0) {
      for (const excludedTelephone of excludedTelephoneList) {
        await fetchPhoneDelete({
          phone_id: excludedTelephone.id
        })
      }
    }

    if (telephoneList?.length > 0) {
      for (const telephone of telephoneList) {
        if (telephone.isNewRegister) {
          await fetchPhoneCreate({
            phone: telephone.phoneNumber,
            phone_type_tag: telephone.phoneType,
          })
        } else if (telephone.isUpdatedRegister) {
          await fetchPhoneUpdate({
            phone: telephone.phoneNumber,
            phone_type_tag: telephone.phoneType,
            phone_id: telephone.id,
          })
        }
      }
    }

    const enterpriseObject = {
      ...(!!socialName && socialName !== oldValues?.socialName) && {
        social_name: socialName
      },
      ...(!!fantasyName && fantasyName !== oldValues?.fantasyName) && {
        fantasy_name: fantasyName
      },
      ...(hasDigitalAccount && {
        ...(!!legalNature && legalNature !== oldValues?.legalNature) && {
          legal_nature_id: legalNature
        },
        ...(!!constitutionForm && constitutionForm !== oldValues?.constitutionForm) && {
          constitution_form_id: String(constitutionForm)
        },
        ...(!!formationDate && formationDate !== oldValues?.formationDate) && {
          formation_date: formationDate
        },
        ...(!!mainActivity && mainActivity !== oldValues?.mainActivity) && {
          main_activity_id: String(mainActivity)
        },
        ...(!!monthlyRevenue && monthlyRevenue !== oldValues?.monthlyRevenue) && {
          monthly_revenue: monthlyRevenue
        },
      }),
    }

    if (Object.keys(enterpriseObject).length > 0) {
      await fetchUpdateAccountData(enterpriseObject)
    }

    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    constitutionList,
    legalNatureList,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    hasDigitalAccount,
  })
}