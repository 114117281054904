import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const SecureAgainstFraudContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 45px 16px;
  margin-bottom: 0px;
  padding-bottom: 48px;
  border-bottom: 1px solid ${grayScale.gray_60p100};
  @media (min-width: 992px)
  {
    margin: 60px 120px;
    margin-bottom: 0px;
    padding-bottom: 120px;
  }
`
export const Title = styled.span`
  ${textStyles.landing_bold_18px}
  color: ${grayScale.black};
  margin-bottom: 16px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_bold_35px}
  }
`
export const Subtitle = styled.span`
  ${textStyles.landing_600_16px}
  color: ${grayScale.gray_3};
  text-align: center;
  margin-bottom: 40px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_600_18px}
    margin-bottom: 80px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px)
  {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const SecureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  :not(:last-child){
    margin-bottom: 42px;
  }
  i{
    margin-bottom: 12px;
  }
  @media (min-width: 992px)
  {
    flex-direction: row;
    i{
      margin-right: 24px;
    }
  }
`
export const SecureText = styled.span`
  ${textStyles.landing_400_14px}
  max-width: 440px;
  text-align: center;
  @media (min-width: 992px)
  {
    text-align: start;
    ${textStyles.landing_400_16px}
  }
`
export const SecureImage = styled.img`
  display: none;
  max-width: 100%;
  @media (min-width: 992px)
  {
    display: initial;
    margin-right: 80px;
  }
`