import YapayIcon from 'assets/icons/YapayIcon';
import React from 'react';
import StickyHeader from 'components/LandingPage/StickyHeader';
import useWindowWidth from 'utils/useWindowWidth';
import { fetchSearch } from 'services/StoreKeys/POST/SearchService';
import { fetchStore } from 'services/StoreKeys/POST/StoreService';
import { AppTitle, InstallAppBannerContainer, Column, AppBody, StyledButton } from './styles';
import { useHistory } from 'react-router-dom';
import { getDataStorage } from 'utils/storage';

export default function InstallAppBanner({ CookieRequired = false }) {
  const windowWidth = useWindowWidth()
  const history = useHistory()

  const handleInstallApp = async () => {
    if (!CookieRequired) {

      await fetchSearch()
        .then(async (response) => {
          const storeHost = getDataStorage('store_host')
          const storeID = getDataStorage('store_id')
          const storePlanID = getDataStorage('store_plan_id')
          const adminUser = getDataStorage('store_admin_user')
          const callbackLoginURL = `/login?store=${storeID}&adm_user=${adminUser}&store_plan_id=${storePlanID}&url=${storeHost}`
          if (typeof (response?.onboarding_step) !== "number") {
            fetchStore().then(({ success }) => {
              if (success) {
                history.replace(callbackLoginURL)
              }
            })
          } else {
            history.replace(callbackLoginURL)
          }
        })
    }
  }

  return <>
    <StickyHeader
      handleInstallApp={handleInstallApp}
      CookieRequired={CookieRequired}
    />
    <InstallAppBannerContainer id="appBaner">
      {
        !(windowWidth >= 992) &&
        <StyledButton
          onClick={handleInstallApp}
          disabled={CookieRequired}
        >
          Instalar App
        </StyledButton>
      }
      <Column>
        <AppTitle>
          Aplicativo Vindi
        </AppTitle>
        <AppBody>
          {`Consultas com um clique!\nAcesse transações e principais configurações diretamente no portal da sua loja Tray.`}
        </AppBody>
      </Column>
      <Column $centered $hasOffset>
        <YapayIcon
          color="white"
          size={windowWidth >= 992 ? 1.9 : 0.95}
        />
        {
          windowWidth >= 992 &&
          <StyledButton
            onClick={handleInstallApp}
            disabled={CookieRequired}
          >
            Instalar App
          </StyledButton>
        }
      </Column>
    </InstallAppBannerContainer>
  </>;
}
