import { individualLegalNatureBusinessTags } from 'library/individualBusinessLegalNatureTags';
import { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { fetchGetAccountPendencies } from 'services/Account/GET/GetAccountPendenciesService';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { fetchGetBankAccount } from 'services/Account/GET/GetBankAccountService';
import { fetchGetConstitution } from 'services/Account/GET/GetConstitutionService';
import { fetchGetLegalNatureList } from 'services/Account/GET/GetLegalNatureListService';
import { fetchUpdateAccountData } from 'services/Account/POST/UpdateAccountData';
import { fetchGetAddress } from 'services/Address/GET/GetAddressService';
import { fetchUpdateAddress } from 'services/Address/POST/UpdateAddressService';
import { fetchBankOptions } from 'services/BankAccounts/GET/BankOptionsService';
import { fetchBankAccountCreate } from 'services/BankAccounts/POST/BankAccountCreateService';
import { fetchBankAccountType } from 'services/BankAccounts/POST/BankAccountTypeService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { fetchPhoneList } from 'services/Phone/GET/PhoneListService';
import { fetchPhoneCreate } from 'services/Phone/POST/PhoneCreateService';
import { fetchPhoneDelete } from 'services/Phone/POST/PhoneDeleteService';
import { fetchPhoneUpdate } from 'services/Phone/POST/PhoneUpdateService';
import { filterActiveBankAccount } from 'utils/filterActiveBankAccount';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatRG } from 'utils/formatRG';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { deleteDataStorage, getDataStorage, setDataStorage } from 'utils/storage';
import { validateBank } from 'utils/validateBank';
import { object, string, date, number, array } from 'yup';

export default function useAdditionalDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
  afterSubmitAlternative = () => { },
  showPartnersPercentageSection = false,
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [displayedName, setDisplayedName] = useState("")
  const [identifier, setIdentifier] = useState("")
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const [constitutionList, setConstitutionList] = useState([])
  const [legalNatureList, setLegalNatureList] = useState([])
  const [bankOptionsList, setBankOptionsList] = useState([])
  const [accountTypesList, setAccountTypesList] = useState([])
  const [validationSchema, setValidationSchema] = useState()
  const [missingPendencies, setMissingPendencies] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const fetchAccountData = useCallback(async () => {
    const [
      { type },
      fetchedMyAccount,
      fetchedAdress,
      fetchedTelephoneList,
      fetchedBankOptions,
      fetchedBankAccountTypes,
      fetchedBankAccountList,
      missingPendenciesResponse,
    ] = await Promise.all([
      fetchGetAccount(),
      fetchGetAccountMyAccount(),
      fetchGetAddress(),
      fetchPhoneList(),
      fetchBankOptions(),
      fetchBankAccountType(),
      fetchGetBankAccount(),
      fetchGetAccountPendencies({}),
    ])

    const fetchedBankAccount = filterActiveBankAccount(fetchedBankAccountList)
    const validationObject = {
      telephoneList: array()
        .of(
          object()
            .shape({
              phoneNumber: string()
                .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
                .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
                .required('Este campo deve ser preenchido ou excluído'),
              phoneType: string()
                .required("Este campo é obrigatório"),
            })
        ),
      excludedTelephoneList: array(),
      address: object()
        .shape({
          street: string(),
          number: string(),
          complement: string(),
          neighborhood: string(),
          city: string(),
          state: string(),
          zipCode: string()
            .min(9, 'O CEP informado é inválido'),
        }),
      bankAccount: object()
        .shape({
          bankCode: string()
            .test('valid-bank', 'O banco informado é inválido', (receivedBankCode) =>
              validateBank({
                receivedBankCode,
                bankOptionsList: fetchedBankOptions,
              })
            )
            .required("Este campo é obrigatório"),
          bankAccountType: string()
            .required("Este campo é obrigatório"),
          bankAgency: string()
            .length(4, "Este campo deve possuir 4 dígitos")
            .required("Este campo é obrigatório"),
          bankAccountNumber: string()
            .max(10, "Este campo deve possuir no máximo 10 dígitos")
            .required("Este campo é obrigatório"),
          verifyingDigit: string()
            .max(2, "Este campo deve possuir no máximo 2 dígitos")
            .required("Este campo é obrigatório"),
        }),
      ...(type === "business" && {
        legalNature: string()
          .required('Este campo é obrigatório'),
        constitutionForm: string()
          .required('Este campo é obrigatório'),
        formationDate: date()
          .nullable()
          .typeError("Informe uma data válida"),
        monthlyRevenue: number()
          .required('Este campo é obrigatório'),
        mainActivity: string()
          .required('Este campo é obrigatório'),
        mainActivitySearchValue: string(),
        hasPartnersWithOver25Percent: string()
          .required('Este campo é obrigatório'),
      }),
      ...(type !== "business" && {
        profession: string()
          .required('Este campo é obrigatório'),
        professionSearchValue: string(),
        monthlyIncome: number()
          .required('Este campo é obrigatório'),
        isPPE: string()
          .required('Este campo é obrigatório'),
        RG: string()
          .min(11, 'O RG informado deve possuir ao menos 11 dígitos')
          .max(12, 'O RG informado deve possuir no máximo 12 dígitos')
          .required("Este campo é obrigatório"),
        RGIssueDate: date()
          .nullable(),
        motherName: string()
          .required("Este campo é obrigatório"),
        birthDate: date()
          .nullable()
          .typeError("Informe uma data válida"),
      }),
    }

    const initialValuesObject = {
      telephoneList: fetchedTelephoneList || [{
        phoneType: "business",
        phoneNumber: "",
        isNewRegister: true,
        isUpdatedRegister: false,
      }],
      excludedTelephoneList: [],
      address: {
        street: fetchedAdress?.street || "",
        number: fetchedAdress?.number || "",
        complement: fetchedAdress?.complement || "",
        neighborhood: fetchedAdress?.neighborhood || "",
        city: fetchedAdress?.city || "",
        state: fetchedAdress?.state || "",
        zipCode: parseStringToCEP(fetchedAdress?.zipcode || ""),
      },
      bankAccount: {
        bankCode: fetchedBankAccount?.code || "",
        bankAccountType: fetchedBankAccount?.bank_account_type?.id || "",
        bankAgency: fetchedBankAccount?.agency || "",
        bankAccountNumber: fetchedBankAccount?.number?.split("-")?.[0] || "",
        verifyingDigit: fetchedBankAccount?.number?.split("-")?.[1] || "",
        pj: fetchedBankAccount?.pj || type === "business",
        name: fetchedBankAccount?.name || fetchedMyAccount?.name || fetchedMyAccount?.social_name || "",
      },
      ...(type === "business" && {
        legalNature: fetchedMyAccount?.legal_nature?.id || "",
        constitutionForm: fetchedMyAccount?.constitution_form?.id || "",
        formationDate: fetchedMyAccount?.formation_date?.replace(/-/g, '/') || null,
        monthlyRevenue: parseFloat(fetchedMyAccount?.monthly_revenue || 0),
        mainActivity: fetchedMyAccount?.main_activity?.id || "",
        mainActivitySearchValue: fetchedMyAccount?.main_activity?.name || "",
        hasPartnersWithOver25Percent: String(getDataStorage('hasPartnersWithOver25Percent') || false),
      }),
      ...(type !== "business" && {
        profession: fetchedMyAccount?.profession?.id || "", // TO-DO: Aprimorar obtenção de dados inicias
        professionSearchValue: fetchedMyAccount?.profession?.name || "",
        monthlyIncome: parseFloat(fetchedMyAccount?.personal_monthly_income || 0),
        isPPE: String(fetchedMyAccount?.politically_exposed || false),
        RG: formatRG(fetchedMyAccount?.rg || ""),
        RGIssueDate: fetchedMyAccount?.rg_issue_date?.replace(/-/g, '/') || null,
        motherName: fetchedMyAccount?.mother_name || "",
        birthDate: fetchedMyAccount?.birth_date?.replace(/-/g, '/') || null,
      }),
    }

    let fetchedConstitutionList
    let fetchedLegalNatureList

    if (type === "business") {
      [fetchedConstitutionList, fetchedLegalNatureList] = await Promise.all([
        fetchGetConstitution(),
        fetchGetLegalNatureList(),
      ])
    }

    if (mounted.current) {
      setDisplayedName(type === "business" ? fetchedMyAccount?.social_name : fetchedMyAccount?.name)
      setIdentifier(type === "business" ? fetchedMyAccount?.cnpj : formatIdentifier(fetchedMyAccount?.cpf))
      setBusinessAccount(type === "business")
      setBankOptionsList(fetchedBankOptions || [])
      setAccountTypesList(fetchedBankAccountTypes || [])
      setOldValues(initialValuesObject)
      setInitialValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setMissingPendencies(missingPendenciesResponse)
      if (type === "business") {
        setConstitutionList(fetchedConstitutionList)
        setLegalNatureList(fetchedLegalNatureList)
      }
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    mainActivity,
    constitutionForm,
    formationDate,
    telephoneList,
    excludedTelephoneList,
    address,
    bankAccount,
    legalNature,
    monthlyRevenue,
    hasPartnersWithOver25Percent,
    profession,
    monthlyIncome,
    isPPE,
    RG,
    RGIssueDate,
    motherName,
    birthDate,
  }) => {
    const accountObject = {
      // PJ
      ...(!!mainActivity && mainActivity !== oldValues?.mainActivity) && {
        main_activity_id: String(mainActivity)
      },
      ...(!!constitutionForm && constitutionForm !== oldValues?.constitutionForm) && {
        constitution_form_id: String(constitutionForm)
      },
      ...(!!formationDate && formationDate !== oldValues?.formationDate) && {
        formation_date: formationDate
      },
      ...(!!legalNature && legalNature !== oldValues?.legalNature) && {
        legal_nature_id: legalNature
      },
      ...(monthlyRevenue !== oldValues?.monthlyRevenue) && {
        monthly_revenue: monthlyRevenue
      },
      // PF
      ...(!!profession && profession !== oldValues?.profession) && {
        profession_id: profession
      },
      ...(!!monthlyIncome && monthlyIncome !== oldValues?.monthlyIncome) && {
        personal_monthly_income: monthlyIncome
      },
      ...(isPPE !== oldValues?.isPPE) && {
        politically_exposed: isPPE
      },
      ...(!!RG && RG !== oldValues?.RG) && {
        rg: RG
      },
      ...(!!RGIssueDate && RGIssueDate !== oldValues?.RGIssueDate) && {
        rg_issue_date: RGIssueDate
      },
      ...(!!motherName && motherName !== oldValues?.motherName) && {
        mother_name: motherName
      },
      ...(!!birthDate && birthDate !== oldValues?.birthDate) && {
        birth_date: birthDate
      },
    }

    if (Object.keys(accountObject).length > 0) {
      await fetchUpdateAccountData(accountObject)
    }

    // Comum entre PJ e PF
    if (JSON.stringify(address) !== JSON.stringify(oldValues.address) &&
      !!address.street &&
      !!address.number &&
      !!address.neighborhood &&
      !!address.city &&
      !!address.state &&
      !!address.zipCode
    ) {
      await fetchUpdateAddress({
        street: address.street,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        zipcode: address.zipCode,
      })
    }

    if (excludedTelephoneList?.length > 0) {
      for (const excludedTelephone of excludedTelephoneList) {
        await fetchPhoneDelete({
          phone_id: excludedTelephone.id
        })
      }
    }

    if (telephoneList?.length > 0) {
      for (const telephone of telephoneList) {
        if (telephone.isNewRegister) {
          await fetchPhoneCreate({
            phone: telephone.phoneNumber,
            phone_type_tag: telephone.phoneType,
          })
        } else if (telephone.isUpdatedRegister) {
          await fetchPhoneUpdate({
            phone: telephone.phoneNumber,
            phone_type_tag: telephone.phoneType,
            phone_id: telephone.id,
          })
        }
      }
    }

    if (JSON.stringify(bankAccount) !== JSON.stringify(oldValues.bankAccount) &&
      !!bankAccount.bankCode &&
      !!bankAccount.bankAccountType &&
      !!bankAccount.bankAccountNumber &&
      !!bankAccount.bankAgency &&
      !!bankAccount.verifyingDigit
    ) {
      await fetchBankAccountCreate({
        code: bankAccount.bankCode,
        bank_account_type_id: bankAccount.bankAccountType,
        number: `${bankAccount.bankAccountNumber}-${bankAccount.verifyingDigit}`,
        agency: bankAccount.bankAgency,
        name: bankAccount.name,
        pj: bankAccount.pj,
      })
    }

    if (isBusinessAccount) {
      const selectedLegalNatureTag = legalNatureList.filter((e) => e.id === legalNature)?.[0]?.tag
      if (showPartnersPercentageSection && !individualLegalNatureBusinessTags.includes(selectedLegalNatureTag)) {
        deleteDataStorage('hasPartnersWithOver25Percent')
        setDataStorage('hasPartnersWithOver25Percent', JSON.parse(hasPartnersWithOver25Percent))
      }
    }

    if (reloadAfterSubmit) {
      await fetchAccountData()
    }
    setSnackbarOpen(true)
    if (isBusinessAccount) {
      afterSubmitAlternative()
    } else {
      afterSubmit()
    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    displayedName,
    identifier,
    isBusinessAccount,
    constitutionList,
    legalNatureList,
    bankOptionsList,
    accountTypesList,
    missingPendencies,
    isSnackbarOpen,
    setSnackbarOpen,
  })
}