import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { parseStringToCNPJ } from 'utils/parseStringToCNPJ';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { fetchUploadCompany } from 'services/Document/POST/UploadCompanyService';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function useEnterpriseProofingUploadSetup({
  afterSubmit = () => { },
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [enterpriseData, setEnterpriseData] = useState({})
  const [documentRequired, setDocumentRequired] = useState(false)
  const [documentStatus, setDocumentStatus] = useState()
  const initialValues = {
    enterpriseProofingPhoto: "",
    selectedDocumentType: "ccmei",
  }
  const validationSchema = object({
    enterpriseProofingPhoto: string()
      .required(),
    selectedDocumentType: string()
      .required(),
  })

  const fetchAccountData = useCallback(async () => {
    let { cnpj, social_name } = await fetchGetAccountMyAccount()
    cnpj = parseStringToCNPJ(cnpj)
    if (mounted.current) {
      setEnterpriseData({
        cnpj,
        social_name
      })
      setLoading(false)
    }
  }, [])

  const getDocumentStatus = useCallback(async () => {
    fetchGetDocumentStatus("company")
      .then((data) => {
        if (mounted.current) {
          setDocumentStatus(data)
          if (["pending", "approved"].includes(data?.status?.tag)) {
            setDocumentRequired(false);
          } else {
            setDocumentRequired(true)
          }
        }
      })
  }, [])

  const handleSubmit = async ({
    enterpriseProofingPhoto,
    selectedDocumentType,
  }) => {
    if (enterpriseProofingPhoto) {
      const fileEnterpriseProofingPhoto = await parseDataURLtoFile(enterpriseProofingPhoto, "enterpriseProofingPhoto")
      await fetchUploadCompany({
        file: fileEnterpriseProofingPhoto,
        category: selectedDocumentType,
        group: "company"
      })
        .then((res) => {
          if (res?.status === 200) {
            GAEventTracker({
              category: "Onboarding",
              action: "Anexar documento de constituiçao da empresa",
              label: "Anexar aconstituiçao da empresa e salvar"
            })
          }
          afterSubmit()
        })
    } else {
      afterSubmit()
    }
  }

  useEffect(() => {
    fetchAccountData()
    getDocumentStatus()
  }, [fetchAccountData, getDocumentStatus])


  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    enterpriseData,
    documentRequired,
    documentStatus,
  })
}