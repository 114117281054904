import apiPrivate from 'services/apiPrivate'
import { deleteDataStorage, setDataStorage } from 'utils/storage'
export const fetchAccessToken = async (code, history = null) => {
  code = code.replace("/auth/callback/", "")
  setDataStorage('yapay_code', code)
  return apiPrivate.get("/signin/tokens", {
    headers: {
      code
    }
  }).then(async ({ data }) => {
    const { token } = data
    if (token) {
      setDataStorage("session_id", token)
    } else {
      deleteDataStorage("session_id")
      deleteDataStorage("yapay_code")
      if (history) {
        history?.replace("/login")
      }
    }
  }).catch((error) => {
    deleteDataStorage("session_id")
    deleteDataStorage("yapay_code")
    if (history) {
      history?.replace("/login")
    }
  })
}