import React from 'react';
import { SettingsMenuContainer } from './styles';
import { MenuItem, MenuList, ItemDescription, ItemTitle, ItemColumn, ImageCard, Row, SmallInstruction, StyledButton } from './styles';
import { Button } from '@material-ui/core';
import ConfigImage from 'assets/images/config.png'
import { menuDictionary } from 'components/Dashboard/DashboardSettings/DashboardSettings';
import { useDashboard } from 'context/DashboardContext';
import ExpandMoreLeftIcon from 'assets/icons/ExpandMoreLeftIcon';
import ExternalService from 'utils/ExternalService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function SettingsMenu() {
  const { setCurrentSettingsMenuItem } = useDashboard()

  const handleSelectMenuItem = (e) => {
    switch (e) {
      case "customize":
        GAEventTracker({
          category: "Página configurações",
          action: "Personalização",
          label: "Clicar em 'Personalização' na pag de configurações"
        })
        break;
      case "creditCard":
        GAEventTracker({
          category: "Página configurações",
          action: "Cartão de cŕedito",
          label: "Clicar em 'Cartão de crédito' na pag de configurações"
        })
        break;
      case "boleto":
        GAEventTracker({
          category: "Página configurações",
          action: "Boleto",
          label: "Clicar em 'Boleto' na pag de configurações"
        })
        break;
      case "billingEmail":
        GAEventTracker({
          category: "Página configurações",
          action: "E-mails transacionais",
          label: "Clicar em 'E-mails transacionais' na pag de configurações"
        })
        break;

      default:
        break;
    }
    setCurrentSettingsMenuItem(e)
  }

  const handleOpenPortal = () => {
    GAEventTracker({
      category: "Página configurações",
      action: "Acessar portal",
      label: "Clicar em 'acessar portal' na página de configurações"
    })
    window.open(`${ExternalService.buildExternalUrl('intermediador')}/`, '_blank')
  }

  return <>
    <SettingsMenuContainer>
      <Row>
        <MenuList>
          {
            Object.keys(menuDictionary).map((e, i) => {
              return (
                <MenuItem
                  key={i}
                  as={Button}
                  role="li"
                  onClick={() => { handleSelectMenuItem(e) }}
                >
                  {menuDictionary[e].icon}
                  <ItemColumn>
                    <ItemTitle>
                      {menuDictionary[e].title}
                    </ItemTitle>
                    <ItemDescription>
                      {menuDictionary[e].description}
                    </ItemDescription>
                  </ItemColumn>
                  <ExpandMoreLeftIcon />
                </MenuItem>
              )
            })
          }
        </MenuList>
        <ImageCard>
          <img src={ConfigImage} alt="Imagem de um boleto" />
        </ImageCard>
      </Row>
      <SmallInstruction>
        Para mais configurações, acesse o portal Vindi.
      </SmallInstruction>
      <StyledButton
        variant="outlined"
        onClick={handleOpenPortal}
      >
        Acessar portal
      </StyledButton>
    </SettingsMenuContainer>
  </>;
}
