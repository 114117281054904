import React from 'react';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import UserIdUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/UserIdUploadForm';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import useUserIdUploadSetup from 'components/FormsSetup/AccountVerification/useUserIdUploadSetup';
import { UserIdUploadStepContainer } from './styles';
import LoadableButton from 'components/shared/LoadableButton';
import { Button } from '@material-ui/core';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { getHideOnCountStepsGoingForward } from 'utils/getHideOnCountStepsGoingForward';
import { accountVerificationStepsDictionary } from 'library/accountVerificationStepsDictionary';

export default function UserIdUploadStep() {
  const { nextAccountVerificationStep, accountVerificationStep, isBusinessAccount, nextOnboardingSection } = useOnboarding()
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("documentsSendingSteps").length - 1
  const { initialValues, validationSchema, isLoading, handleSubmit, userData, documentRequired, documentStatus } = useUserIdUploadSetup({
    afterSubmit: () => {
      if (accountVerificationStep < stepsCollectionLength) {
        const hideOnCountStepsGoingBack = getHideOnCountStepsGoingForward("documentsSendingSteps", accountVerificationStep, accountVerificationStepsDictionary)
        if (accountVerificationStep + hideOnCountStepsGoingBack <= stepsCollectionLength) {
          for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
            nextAccountVerificationStep()
          }
        } else {
          nextOnboardingSection()
        }
      } else {
        nextOnboardingSection()
      }
    },
    afterSubmitAlternative: () => {
      if (accountVerificationStep < stepsCollectionLength) {
        nextAccountVerificationStep()
      } else {
        nextOnboardingSection()
      }
    },
  })

  return <>
    <UserIdUploadStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserIdUploadForm
                userData={userData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                isBusinessAccount={isBusinessAccount}
                showAttorneyCheckbox
                {...formikProps}
              />
              <SameSizeButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_envio_foto_RG_onboarding_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={nextAccountVerificationStep}
                  id={"tela_envio_foto_RG_onboarding_botao_enviar_depois"}
                >
                  Enviar depois
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </UserIdUploadStepContainer>
  </>;
}
