import YapayIcon from 'assets/icons/YapayIcon';
import React from 'react';
import { InnerContainer, InstructionsContainer, LoginContainer, StyledButton } from './styles';
import ExternalService from 'utils/ExternalService';
import { useState } from 'react';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { fetchSigninLogout } from 'services/Logout/GET/SigninLogoutService';
import { deleteDataStorage } from 'utils/storage';
import { useEffect } from 'react';
import { fetchBondAccountDetailYapay } from 'services/BondAccount/GET/BondAccountDetailYapayService';
import { useLocation } from 'react-router-dom';
import { GAMapRoutes } from 'utils/GAMapRoutes';

export default function Logout() {
  GAMapRoutes()
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState("")

  useEffect(() => {
    fetchBondAccountDetailYapay()
      .then(({ email }) => {
        setEmail(email)
        setLoading(false)
      })
  }, [])

  const handleNavigationToYapayLogin = async () => {
    setLoading(true)
    await fetchSigninLogout()
    deleteDataStorage("session_id")
    deleteDataStorage("yapay_code")
    const yapayPortalURL = `${ExternalService.buildExternalUrl('signin')}/login/plugincredenciamento`
    window.open(yapayPortalURL, "_parent")
    setLoading(false)
  }
  function usePageViews() {
    const location = useLocation()
    useEffect(() => {
      console.log(["pageview", location.pathname])
    }, [location])
  }
  usePageViews()
  return <>
    {loading ?
      <LoadingIndicator /> :
      <LoginContainer>
        <YapayIcon color="white" size={1.18} />
        <InnerContainer>
          <>
            <InstructionsContainer>
              <span>
                {'Ops!\nA conta que você tentou acessar no app não é a mesma conta vinculada à sua loja Tray.'}
              </span>
              <span>
                {`Utilize o mesmo e-mail da sua conta da loja Tray\n`}
                <b> {email} </b>
                {`${!!email ? "\n" : ""}e informe o acesso para logar na Vindi.`}
              </span>
              <span>
                {'Se você ainda nao possui senha Vindi ou a esqueceu, clique em "Não tenho senha" e informe o mesmo e-mail acima.'}
              </span>
            </InstructionsContainer>
            <StyledButton
              onClick={handleNavigationToYapayLogin}
            >
              OK, IR PARA O LOGIN
            </StyledButton>
            <StyledButton
              variant="outlined"
              onClick={handleNavigationToYapayLogin}
            >
              NÃO TENHO SENHA
            </StyledButton>
          </>
        </InnerContainer>
      </LoginContainer>}
  </>;
}
