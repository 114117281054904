import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const BusinessAdditionalFieldsContainer = styled.div`
  
`
export const BusinessAccountDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  @media (min-width: 920px)
  {
    margin-bottom: 68px;
  }
`
export const BusinessAccountDataInnerRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 920px)
  {
    flex-direction: row;
  }
`
export const BusinessAccountDataInnerColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  >img{
    display: none;
  }
  @media (min-width: 920px)
  {
    >img{
      display: initial;
      margin: 0px auto;
      margin-top: -22px;
    }
  }
`
export const CNAEInstructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: -12px;
  margin-bottom: 24px;
`
export const SocietyPercentageInstructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: -16px;
  margin-bottom: 16px;
`
export const BankInstructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: -16px;
  margin-bottom: 24px;
`