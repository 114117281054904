import AtendimentoIcon from 'assets/icons/AtendimentoIcon';
import ChargebackIcon from 'assets/icons/ChargebackIcon';
import ConfigIcon from 'assets/icons/ConfigIcon';
import MensalIcon from 'assets/icons/MensalIcon';
import PagNFinIcon from 'assets/icons/PagNFinIcon';
import SaqueIcon from 'assets/icons/SaqueIcon';
import React from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import { WithoutAdditionalChargeContainer, FeatureItem, Row, Subtitle, Title, IconContainer, FeatureText } from './styles';

const features = [
  {
    title: "Saque",
    icon: SaqueIcon,
  },
  {
    title: "Atendimento",
    icon: AtendimentoIcon,
  },
  {
    title: "Chargeback",
    icon: ChargebackIcon,
  },
  {
    title: "Configuração",
    icon: ConfigIcon,
  },
  {
    title: "Mensalidade",
    icon: MensalIcon,
  },
  {
    title: "Pagamentos não finalizados",
    icon: PagNFinIcon,
  },
]

export default function WithoutAdditionalCharge() {
  const windowWidth = useWindowWidth()
  return <>
    <WithoutAdditionalChargeContainer>
      <Title>
        Sem custo adicional
      </Title>
      <Subtitle>
        Na Vindi você possui todos esses benefícios, e o melhor, sem cobrança de:
      </Subtitle>
      <Row>
        {
          features.map((e, i) => {
            return (
              <FeatureItem key={i}>
                <IconContainer>
                  {e.icon({
                    size: windowWidth < 992 ? 0.8 : 1
                  })}
                </IconContainer>
                <FeatureText>
                  {e.title}
                </FeatureText>
              </FeatureItem>
            )
          })
        }
      </Row>
    </WithoutAdditionalChargeContainer>
  </>;
}
