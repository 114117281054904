import apiPublic from 'services/apiPublic'
import { getDataStorage } from 'utils/storage'

export const fetchBondAccountDetailYapay = async () => {
  const store_url = getDataStorage('store_URL')
  const store_access_token = getDataStorage('store_access_token')

  return apiPublic.get("/bond/account/detail", {
    headers: {
      store_url,
      store_access_token,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}