import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const WithoutAdditionalChargeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 16px;
  padding-bottom: 8px;
  @media (min-width: 992px)
  {
    padding: 60px 120px;
    padding-bottom: 100px;
  }
`
export const Title = styled.span`
  ${textStyles.landing_bold_18px}
  color: ${grayScale.black};
  margin-bottom: 16px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_bold_35px}
  }
`
export const Subtitle = styled.span`
  ${textStyles.landing_600_16px}
  color: ${grayScale.gray_3};
  margin-bottom: 32px;
  text-align: center;
  max-width: 260px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_600_18px}
    margin-bottom: 64px;
    max-width: initial;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
`
export const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50%;
  margin-bottom: 32px;
  @media (min-width: 992px)
  {
    max-width: 87px;
  :last-child{
    max-width: 115px;
    margin-left: -14px;
  }
  :not(:last-child){
    margin-right: 50px;
  }
  }
`
export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: ${primaryColors.baby_blue};
  border-radius: 16px;
  margin-bottom: 10px;
  @media (min-width: 992px)
  {
    width: 87px;
    height: 87px;
    margin-bottom: 24px;
  }
`
export const FeatureText = styled.div`
  ${textStyles.landing_400_16px}
  text-align: center;
`