import React, { useState, useCallback, useEffect } from 'react';
import AboutYapay from 'components/LandingPage/AboutYapay';
import AppBenefits from 'components/LandingPage/AppBenefits';
import Footer from 'components/LandingPage/Footer';
import InstallAppBanner from 'components/LandingPage/InstallAppBanner';
import ManyPaymentOptions from 'components/LandingPage/ManyPaymentOptions';
import SecureAgainstFraud from 'components/LandingPage/SecureAgainstFraud';
import SingInLess from 'components/LandingPage/SingInLess';
import WithoutAdditionalCharge from 'components/LandingPage/WithoutAdditionalCharge';
import { useHistory } from 'react-router';
import { fetchSearch } from 'services/StoreKeys/POST/SearchService';
import { checkCookie, setDataStorage } from 'utils/storage';
import { useQuery } from 'utils/useQuery';
import { useRef } from 'react';
import StickyAlert from 'components/LandingPage/StickyAlert/StickyAlert';
import { GAMapRoutes } from 'utils/GAMapRoutes';

export default function LandingPage() {
  GAMapRoutes()
  const query = useQuery();
  const history = useHistory()
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {

  }, [])



  const [loading, setLoading] = useState(true)
  const [cookieRequired, setCookieRequired] = useState(false)

  const getQueryParams = useCallback(async () => {

    if (!checkCookie()) {
      setCookieRequired(true)
    } else {
      setCookieRequired(false)
      const storeHost = query.get("store_host") || query.get("url")
      const storeID = query.get("store")
      const storePlanID = query.get("store_plan_id")
      const adminUser = query.get("adm_user")
      if (storeID && adminUser) { // precisa ser adm
        setDataStorage('store_host', decodeURI(storeHost))
        setDataStorage('store_id', storeID)
        setDataStorage('store_plan_id', storePlanID)
        setDataStorage('store_admin_user', adminUser)
        const res = await fetchSearch(storeID, adminUser)
        if (mounted.current) {
          if (res?.length !== 0) {
            const callbackLoginURL = `/login?store=${storeID}&adm_user=${adminUser}&store_plan_id=${storePlanID}&url=${storeHost}`
            history.replace(callbackLoginURL)
          }
        }
      } else {
        history.replace("/login")
      }
    }
    setLoading(false);
  }, [query, history])

  useEffect(() => {
    getQueryParams()
  })
  return <>
    {
      !loading &&
      <>
        <StickyAlert isOpen={cookieRequired} />
        <InstallAppBanner cookieRequired={cookieRequired} />
        <AppBenefits />
        <SingInLess />
        <AboutYapay />
        <ManyPaymentOptions />
        <SecureAgainstFraud />
        <WithoutAdditionalCharge />
        <Footer />
      </>
    }
  </>;
}
