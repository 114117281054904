import React from 'react';
import { AboutYapayContainer, Column, Subtitle, Title, Body, StyledList, ListItem, StyledImage } from './styles';
import YPImage from 'assets/images/landing/yp.png'
import useWindowWidth from 'utils/useWindowWidth';

const integratedResources = [
  "Receba seus pagamentos com segurança",
  "Tenha uma análise antifraude completa",
  "Conte com um time de atendimento exclusivo",
  "Realize a conciliação dos recebíveis num só lugar",
]

export default function AboutYapay() {
  const windowWidth = useWindowWidth()
  return <>
    <AboutYapayContainer>
      <Column>
        <Subtitle>Sobre a Vindi</Subtitle>
        {
          !(windowWidth >= 992) &&
          <StyledImage
            src={YPImage}
            alt="Vindi"
          />
        }
        <Title>
          Intermediador de pagamentos
        </Title>
        <Body>
          Todos os recursos integrados numa só plataforma:
        </Body>
        <StyledList>
          {
            integratedResources.map((e, i) => {
              return (
                <ListItem key={i}>
                  {e}
                </ListItem>
              )
            })
          }
        </StyledList>
      </Column>
      {
        windowWidth >= 992 &&
        <Column>
          <StyledImage
            src={YPImage}
            alt="Vindi"
          />
        </Column>
      }
    </AboutYapayContainer>
  </>;
}
