import styled from "styled-components";
import { buttonStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";
import backgroundImage from "assets/images/landing/background.png"
import { Button } from "@material-ui/core";

export const InstallAppBannerContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  padding: 28px 16px;
  @media (min-width: 992px)
  {
    flex-direction: row;
    align-items: initial;
    padding: 90px 58px;
    min-height: 348px;
    height: 50vh;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $centered }) => $centered && `
    align-items: center;
  `}
  i{
    margin-bottom: 38px;
  }
  @media (min-width: 992px)
  {
    i{
      margin-bottom: 56px;
    }
    ${({ $hasOffset }) => $hasOffset && `
      margin-left: -185px;
      margin-top: 20px;
    `}
  }
`
export const AppTitle = styled.span`
  ${textStyles.landing_700_24px}
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: 992px)
  {
    ${textStyles.landing_bold_40px}
    text-align: start;
  }
`
export const AppBody = styled.span`
  ${textStyles.landing_600_16px}
  font-weight: 500;
  white-space: pre-wrap;
  max-width: 290px;
  text-align: center;
  margin: auto;
  margin-bottom: 48px;
  @media (min-width: 992px)
  {
    ${textStyles.landing_600_20px}
    text-align: start;
    max-width: 576px;
    margin: initial;
    margin-bottom: initial;
    margin-right: 185px;
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.landing_install_app};
  :disabled{
    background: ${primaryColors.gray_graph_2};
    color:${grayScale.gray_4}
  }
`