import React from 'react';
import { Body, SingInLessContainer, Title } from './styles';
import LogoImages from 'assets/images/landing/logos-login 1.png'

export default function SingInLess() {
  return <>
    <SingInLessContainer>
      <img src={LogoImages} alt="Vindi + Tray" />
      <Title>
        Não precisa criar conta!
      </Title>
      <Body>
        {`Ao criar sua loja na Tray, automaticamente você já tem uma conta na Vindi. Por isso, não precisa criar uma nova conta para acessar o app.\n\nE se você ainda não usa a Vindi como intermediador, instale o app e informe o mesmo e-mail da sua conta Tray. \nÉ fácil e seguro para sua loja e seus clientes!`}
      </Body>
    </SingInLessContainer>
  </>;
}
