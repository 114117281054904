import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { AddressProofingUploadFormContainer, AlertContainer, AlertText, AlertTextContainer, TextBody, OuterContainer, ProfileCard, IconContainer, TextContainer, OptionsContainer, ContainerLeft, ContainerRight, Title, FileRulesList } from './styles';
import LocationImage from 'assets/images/landing/location.png'
import PinLocation from 'assets/icons/PinLocation';
import FilePicker from 'components/shared/FilePicker';
import InsertDriveFileIcon from 'assets/icons/InsertDriveFileIcon';
import WarningCircle from 'assets/icons/WarningCircle';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import DocumentsRequiredAlert from 'components/Onboarding/OnboardingForms/AccountVerificationForms/shared/DocumentsRequiredAlert';

export default function AddressProofingUploadForm({
  isLoading,
  addressData = {},
  setFieldValue = () => { },
  documentRequired = false,
  documentStatus,
  sendButtonText = "Continuar",
}) {
  return <>
    <AddressProofingUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <OuterContainer>
              <ContainerLeft>
                <TextBody>
                  Envie um comprovante do seguinte endereço cadastrado em sua conta:
                </TextBody>
                <ProfileCard>
                  <IconContainer>
                    <PinLocation />
                  </IconContainer>
                  <TextContainer>
                    <TextBody>
                      CEP {parseStringToCEP(addressData.zipcode)}
                    </TextBody>
                    <TextBody>
                      {`${addressData.street}, ${addressData.number}`}
                    </TextBody>
                    <TextBody>
                      {addressData.complement}
                    </TextBody>
                    <TextBody>
                      {addressData.neighborhood}
                    </TextBody>
                    <TextBody>
                      {`${addressData.city}/${addressData.state}`}
                    </TextBody>
                  </TextContainer>
                </ProfileCard>
                <Title>
                  Comprovantes aceitos (envie apenas um):
                </Title>
                <TextBody>
                  - Contas de consumo (água, energia, internet, gás ou telefone)
                </TextBody>
                <TextBody>
                  - Carnê de IPTU
                </TextBody>
                <TextBody>
                  - Fatura de condomínio
                </TextBody>
                <TextBody>
                  - Fatura do plano de saúde
                </TextBody>
                <AlertContainer>
                  <WarningCircle />
                  <AlertTextContainer>
                    <AlertText>
                      Atenção: comprovantes que foram emitidos há mais de 3 meses não serão aceitos
                    </AlertText>
                  </AlertTextContainer>
                </AlertContainer>
                <OptionsContainer>
                  <FileRulesList>
                    <li>
                      Formatos de imagem aceitos: jpeg, png, gif, bmp e webp
                    </li>
                    <li>
                      Tamanho máximo do arquivo: 4,5 MB
                    </li>
                  </FileRulesList>
                </OptionsContainer>
                <FilePicker
                  fieldName={"adressProof"}
                  setFieldValue={setFieldValue}
                  title="Adicionar comprovante"
                  Icon={InsertDriveFileIcon}
                  disabled={!documentRequired}
                />
              </ContainerLeft>
              <ContainerRight>
                <img
                  src={LocationImage}
                  alt="Ilustração de documento"
                />
              </ContainerRight>
            </OuterContainer>
            <DocumentsRequiredAlert
              documentStatus={documentStatus}
              sendButtonText={sendButtonText}
            />
          </>
      }
    </AddressProofingUploadFormContainer>
  </>;
}
