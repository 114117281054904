export const accountVerificationVariantsDictionary = {
  personal: {
    digitalAccountVariant: ["titularId", "titularSelfie"],
    revalidatingVariant: ["titularId", "addressProofing"],
    simplifiedVariant: ["titularId", "addressProofing"],
  },
  business: {
    digitalAccountVariant: ["titularId", "titularSelfie", "businessProofing", "partnersId"],
    revalidatingVariant: ["titularId", "addressProofing", "businessProofing"],
    simplifiedVariant: ["titularId", "addressProofing", "businessProofing"],
  },
}