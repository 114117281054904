import { useCallback, useEffect, useState } from 'react';
import { object, string, bool } from 'yup';
import { useRef } from 'react';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { parseStringToCPF } from 'utils/parseStringToCPF';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { fetchUploadDocument } from 'services/Document/POST/UploadDocumentService';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { GAEventTracker } from 'utils/GAEventTracker';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';

export default function useUserIdUploadSetup({
  afterSubmit = () => { },
  afterSubmitAlternative = () => { }
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const [documentRequired, setDocumentRequired] = useState(false)
  const [documentStatus, setDocumentStatus] = useState()
  const initialValues = {
    frontPagePhoto: "",
    versePagePhoto: "",
    selectedDocumentType: "identity_card",
    isAttorney: false,
  }
  const validationSchema = object({
    frontPagePhoto: string()
      .required(),
    versePagePhoto: string()
      .required(),
    selectedDocumentType: string()
      .required(),
    isAttorney: bool(),
  })

  const fetchAccountData = useCallback(async () => {
    let { name, cpf } = await fetchGetAccountMyAccount()
    const { type } = await fetchGetAccount()
    if (mounted.current) {
      setUserData({
        name,
        cpf: parseStringToCPF(cpf),
      })
      setBusinessAccount(type === "business")
      setLoading(false)
    }
  }, [])

  const getDocumentStatus = useCallback(async () => {
    fetchGetDocumentStatus("document")
      .then((data) => {
        if (mounted.current) {
          setDocumentStatus(data)
          if (["pending", "approved"].includes(data?.status?.tag)) {
            setDocumentRequired(false);
          } else {
            setDocumentRequired(true)
          }
        }
      })
  }, [])

  const handleSubmit = async ({
    frontPagePhoto,
    versePagePhoto,
    selectedDocumentType,
    isAttorney,
  }) => {
    if (frontPagePhoto) {
      const fileFrontPagePhoto = await parseDataURLtoFile(frontPagePhoto, "frontPagePhoto")
      await fetchUploadDocument({
        file: fileFrontPagePhoto,
        document_identify: selectedDocumentType,
        document_type: "img_front",
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar foto do documento de frente",
        label: "Anexar uma foto de documento e salvar"
      })
    }
    if (versePagePhoto) {
      const fileVersePagePhoto = await parseDataURLtoFile(versePagePhoto, "versePagePhoto")
      await fetchUploadDocument({
        file: fileVersePagePhoto,
        document_identify: selectedDocumentType,
        document_type: "img_back",
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar foto do documento de verso",
        label: "Anexar uma foto de documento e salvar"
      })
    }
    if (!isAttorney) {
      afterSubmit()
    } else {
      afterSubmitAlternative()
    }
  }

  useEffect(() => {
    fetchAccountData()
    getDocumentStatus()
  }, [fetchAccountData, getDocumentStatus])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    userData,
    documentRequired,
    documentStatus,
    isBusinessAccount,
  })
}