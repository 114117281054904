import React from 'react';
import { CustomizeMenuItemContainer } from './styles';
import { useDashboard } from 'context/DashboardContext';
import { Form, Formik } from 'formik';
import CustomizeForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/CustomizeForm';
import FormButtons from 'components/SettingsMenu/FormButtons';
import useCustomizeSetup from 'components/FormsSetup/MainSettings/useCustomizeSetup';

export default function CustomizeMenuItem() {
  const { disabledEdition } = useDashboard()
  const { initialValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen } = useCustomizeSetup({})

  return <>
    <CustomizeMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <CustomizeForm
                {...formikProps}
                disabled={disabledEdition}
                isLoading={isLoading}
              />
              <FormButtons
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                {...formikProps}
              />
            </Form>
          )
        }
      </Formik>
    </CustomizeMenuItemContainer>
  </>
}
