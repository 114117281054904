import styled from "styled-components";
import { borderStyles, grayScale, textStyles } from "theme/styleguide";

export const AddressProofingUploadFormContainer = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 40px;
  }
`
export const TextBody = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  text-align: left;
`
export const Title = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  text-align: left;
  color: ${grayScale.gray_3};
  margin-bottom: 16px;
  @media (min-width: 768px)
  {
    margin-bottom: 24px;
  }
`
export const FileRulesList = styled.ul`
  padding-left: 16px;
  >li{
    ${textStyles.tray_text_14px}
    color: ${grayScale.gray_3};
    text-align: left;
    opacity: 0.6;
  }
`
export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;
  }
`
export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  >div:last-child{
    align-self: center;
  }
  @media (min-width: 768px){
    max-width: 616px;
    text-align: start;
    >div:last-child{
      align-self: flex-start;
    }
  } 
`
export const ContainerRight = styled.div`
  display: none;
  text-align: center;
  @media (min-width: 768px){
    display: initial;
    width: 100%;
  }
`
export const ProfileCard = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
  padding: 24px;
  ${borderStyles.border_1};
  @media (min-width: 768px){
    margin-bottom: 64px;
  }
`
export const IconContainer = styled.div`
  margin-right: 16px;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const OptionsContainer = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px){
  margin-bottom: 80px;
  }
`
export const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(46, 126, 231, 0.1);
  border-radius: 4px;
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 16px;
  @media (min-width: 768px){
    padding: 18px 24px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
`
export const AlertText = styled.span`
  white-space: pre-wrap;
  color: ${grayScale.gray_3};
  ${textStyles.tray_text_14px}
`
export const AlertTextContainer = styled.div`
  flex: 1;
  margin-left: 16px;
  text-align: initial;
`