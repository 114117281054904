import apiPrivate from 'services/apiPrivate'
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService'
import { getDataStorage } from 'utils/storage'

export const fetchUploadCompany = async ({
  file,
  group,
  category,
  business_partner_id,
}) => {
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id') || await fetchGetAccountMyAccount()
  const formData = new FormData()
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      code,
      token_id,
      ...(!!group && { group }),
      ...(!!category && { category }),
      ...(!!business_partner_id && { business_partner_id }),
    }
  }
  return apiPrivate.post("/document/company", formData, config)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}