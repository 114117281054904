/* eslint-disable max-len */
import React from 'react';

export default function ShoppingCartV2Icon({ size = 1, color = "#236AC8" }) {
  return <>
    <i>
      <svg
        width={38 * size}
        height={38 * size}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.2432 24.5667H12.2449C12.2464 24.5667 12.2478 24.5664 12.2493 24.5664H32.4336C32.9305 24.5664 33.3674 24.2368 33.504 23.759L37.9571 8.17306C38.0531 7.83705 37.9858 7.47581 37.7756 7.19691C37.5651 6.91801 37.2361 6.75392 36.8867 6.75392H9.67569L8.87987 3.17257C8.76651 2.66319 8.31482 2.30079 7.79297 2.30079H1.11328C0.498367 2.30079 0 2.79916 0 3.41407C0 4.02899 0.498367 4.52736 1.11328 4.52736H6.90003C7.04093 5.16198 10.7084 21.6658 10.9194 22.6153C9.73628 23.1296 8.90625 24.3093 8.90625 25.6797C8.90625 27.5213 10.4045 29.0196 12.2461 29.0196H32.4336C33.0485 29.0196 33.5469 28.5212 33.5469 27.9063C33.5469 27.2914 33.0485 26.793 32.4336 26.793H12.2461C11.6323 26.793 11.1328 26.2935 11.1328 25.6797C11.1328 25.0668 11.6306 24.5682 12.2432 24.5667ZM35.4108 8.98048L31.5937 22.3399H13.139L10.1703 8.98048H35.4108Z"
          fill={color}
        />
        <path
          d="M11.1328 32.3594C11.1328 34.2009 12.6311 35.6992 14.4727 35.6992C16.3142 35.6992 17.8125 34.2009 17.8125 32.3594C17.8125 30.5178 16.3142 29.0195 14.4727 29.0195C12.6311 29.0195 11.1328 30.5178 11.1328 32.3594ZM14.4727 31.2461C15.0864 31.2461 15.5859 31.7456 15.5859 32.3594C15.5859 32.9731 15.0864 33.4727 14.4727 33.4727C13.8589 33.4727 13.3594 32.9731 13.3594 32.3594C13.3594 31.7456 13.8589 31.2461 14.4727 31.2461Z"
          fill={color}
        />
        <path
          d="M26.8672 32.3594C26.8672 34.2009 28.3655 35.6992 30.207 35.6992C32.0486 35.6992 33.5469 34.2009 33.5469 32.3594C33.5469 30.5178 32.0486 29.0195 30.207 29.0195C28.3655 29.0195 26.8672 30.5178 26.8672 32.3594ZM30.207 31.2461C30.8208 31.2461 31.3203 31.7456 31.3203 32.3594C31.3203 32.9731 30.8208 33.4727 30.207 33.4727C29.5933 33.4727 29.0938 32.9731 29.0938 32.3594C29.0938 31.7456 29.5933 31.2461 30.207 31.2461Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
