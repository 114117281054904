import AssessmentIcon from 'assets/icons/AssessmentIcon';
import SettingsIcon from 'assets/icons/SettingsIcon';
import DashboardMyAccount from 'components/Dashboard/DashboardMyAccount';
import DashboardOverview from 'components/Dashboard/DashboardOverview';
import DashboardSettings from 'components/Dashboard/DashboardSettings';

export const dashboardTabsDictionary = {
  "overview": {
    title: "Visão Geral",
    icon: AssessmentIcon,
    component: <DashboardOverview />,
  },
  "settings": {
    title: "Configurações",
    icon: SettingsIcon,
    component: <DashboardSettings />,
  },
  "myaccount": {
    title: "Minha Conta",
    icon: SettingsIcon,
    isMainUserOnlyTab: true,
    component: <DashboardMyAccount />,
    subMenuTabs: {
      "accountDataMenu": {
        title: "Dados da conta",
      },
      "userDataMenu": {
        title: "Dados de pessoa usuária",
      },
      "accountVerificationMenu": {
        title: "Envio de documentos",
      }
    }
  }
}